import { LoadingOutlined, PaperClipOutlined } from '@ant-design/icons';
import { useDimensions } from '@xometry/ui';
import { Spin } from 'antd';
import clsx from 'clsx';
import { EllipsisMiddle } from 'components/UI/Typography/EllipsisMiddle';
import { dateFormat } from 'components/utils/formatters';
import React from 'react';

import s from './File.module.less';

interface Props {
  name: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void | Promise<void>;
  loading?: boolean;
  actions?: React.ReactNode;
  url?: string;
  createdAt?: string;
}

export const File = (props: Props) => {
  const [componentRef, dimensions] = useDimensions();
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (props.loading) return;

    void props.onClick?.(e);
  };

  const date = props.createdAt ? dateFormat(props.createdAt) : null;
  const showDate = dimensions.width > 200;

  const handleActionsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <a
      className={clsx(s.file, props.className)}
      href={props.url}
      onClick={handleClick}
      target="_blank"
      rel="noreferrer"
      ref={componentRef}
    >
      <div className={s.content}>
        <PaperClipOutlined />
        <EllipsisMiddle className={s.name} suffixCount={5}>
          {props.name}
        </EllipsisMiddle>
      </div>
      {date && showDate && <div className={s.date}>{date}</div>}

      {props.loading ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
      ) : (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className={s.actions} onClick={handleActionsClick}>
          {props.actions}
        </div>
      )}
    </a>
  );
};
