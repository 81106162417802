import clsx from 'clsx';
import React from 'react';

import s from './SidebarSection.module.less';

interface Props {
  className?: string;
  children: React.ReactNode;
  title?: string;
}

export const SidebarSection: React.FC<Props> = (props) => {
  const { className, children, title } = props;

  return (
    <div className={clsx(className, s.section)}>
      {title && <div className={s.title}>{title}</div>}
      {children}
    </div>
  );
};
