import { getCurrencySymbolByCode } from '@xometry/ui';

import {
  ACCOUNTING_ID_COMMENT_NAME,
  ACCOUNTING_ID_KEY,
  ACCOUNTING_ID_NAME,
  CURRENT_REGION,
  RegionsConfig,
} from './config';
import { RegionData } from './types';

export const toRegion = (probablyRegion?: string | null): RegionData['region'] => {
  if (typeof probablyRegion !== 'string') {
    return CURRENT_REGION;
  }

  const region = probablyRegion.toUpperCase();

  return RegionsConfig[region as keyof typeof RegionsConfig]?.region || CURRENT_REGION;
};

/**
 * Returns regions list.
 * Current region will be first.
 */
export const getRegionsList = () => {
  const list = Object.values(RegionsConfig).filter((config) => config.region !== CURRENT_REGION);

  return [RegionsConfig[CURRENT_REGION], ...list].map((config) => config.region);
};

export const getRegionIcon = (probablyRegion?: string | null): RegionData['icon'] => {
  return RegionsConfig[toRegion(probablyRegion)].icon;
};

export const getRegionCurrency = (probablyRegion?: string | null): RegionData['currency'] => {
  return RegionsConfig[toRegion(probablyRegion)].currency;
};

export const getRegionCurrencySymbol = (probablyRegion?: string | null): string => {
  return getCurrencySymbolByCode(getRegionCurrency(probablyRegion));
};

export const getRegionHost = (probablyRegion?: string | null): RegionData['host'] => {
  return RegionsConfig[toRegion(probablyRegion)].host;
};

export const getRegionCustomersHost = (probablyRegion?: string | null): RegionData['customersHost'] => {
  return RegionsConfig[toRegion(probablyRegion)].customersHost;
};

export const getRegionPartnersHost = (probablyRegion?: string | null): RegionData['partnersHost'] => {
  return RegionsConfig[toRegion(probablyRegion)].partnersHost;
};

export const getRegionOrgName = (probablyRegion?: string | null): RegionData['orgName'] => {
  return RegionsConfig[toRegion(probablyRegion)].orgName;
};

export const getRegionName = (probablyRegion?: string | null): RegionData['name'] => {
  return RegionsConfig[toRegion(probablyRegion)].name;
};

export const getRegionNameShort = (probablyRegion?: string | null): RegionData['shortName'] => {
  return RegionsConfig[toRegion(probablyRegion)].shortName;
};

export const getRegionByCurrencyCode = (code: string): RegionData['region'] => {
  const region = Object.values(RegionsConfig).find((config) => config.currency === code);

  return region?.region || CURRENT_REGION;
};

export const getRegionHeaderColor = (probablyRegion?: string | null): RegionData['headerColor'] => {
  return RegionsConfig[toRegion(probablyRegion)].headerColor;
};

export const getRegionLocale = (probablyRegion?: string | null): RegionData['locale'] => {
  return RegionsConfig[toRegion(probablyRegion)].locale;
};

export const getAccountingId = () => {
  return ACCOUNTING_ID_KEY[CURRENT_REGION];
};

export const getAccountingIdName = () => {
  return ACCOUNTING_ID_NAME[CURRENT_REGION];
};

export const getAccountingIdCommentName = () => {
  return ACCOUNTING_ID_COMMENT_NAME[CURRENT_REGION];
};

export const isCurrentRegion = (region: string) => {
  return CURRENT_REGION.toUpperCase() === region.toUpperCase();
};
