import { Spin } from 'antd';
import AttachedFilesUploader from 'components/shared/AttachedFilesUploader';
import { SidebarSection } from 'components/shared/SidebarSection';
import { LinkLike } from 'components/UI/LinkLike';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { routesApi } from 'routes/api';
import useDownload from 'utils/hooks/useDownload';

interface RouteParams {
  providerId: string;
}

const SidebarRightProvider = observer(() => {
  const { providerId } = useParams<RouteParams>();
  const [loading, setLoading] = useState(false);
  const downloadHelper = useDownload();

  if (!providerId) {
    return null;
  }

  const onRatingDownload = async () => {
    if (loading) return;

    setLoading(true);
    await downloadHelper(routesApi.providerRatingInfoPdf(providerId), `Rating Info-PR-${providerId}.pdf`);
    setLoading(false);
  };

  return (
    <>
      <SidebarSection title="Attached Files">
        <AttachedFilesUploader
          fetchUrl={routesApi.entityAttachments('providers', providerId)}
          uploadUrl={routesApi.entityAttachmentsUploadPath('providers', providerId)}
          destroyUrl={routesApi.entityAttachmentsDestroyPath('providers', providerId)}
        />
      </SidebarSection>
      <SidebarSection title="Related Actions">
        <LinkLike
          semibold
          fontSize={13}
          onClick={() => {
            void onRatingDownload();
          }}
        >
          Rating Info (pdf)
          {loading && <Spin />}
        </LinkLike>
      </SidebarSection>
    </>
  );
});

export default SidebarRightProvider;
