import { Button } from 'antd';
import clsx from 'clsx';
import React from 'react';

import s from './LinkLike.module.less';

interface Props extends React.ComponentProps<typeof Button> {
  children?: React.ReactNode;
  semibold?: boolean;
  disabled?: boolean;
  fontSize?: number;
}

export const LinkLike: React.FC<Props> = (props) => {
  const { children, semibold, disabled, fontSize, ...rest } = props;

  const className = clsx(s.root, props.className, {
    [s.semibold]: semibold,
    [s.disabled]: disabled,
  });

  const style = fontSize ? { fontSize: `${fontSize}px`, ...props.style } : props.style;

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (disabled) return;

    props.onClick?.(e);
  };

  return (
    <Button type="link" size="small" {...rest} className={className} style={style} onClick={handleClick}>
      {children}
    </Button>
  );
};
