import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { routes } from 'routes';
import { useStore } from 'stores/RootStore';

interface Props extends RouteProps {
  component: FunctionComponent<Partial<RouteComponentProps>>;
}

const PrivateRoute = observer((props: Props) => {
  const { userStore } = useStore();
  const { component: Component, ...rest } = props;
  const { isAuthenticated } = userStore;

  useEffect(() => {
    if (!isAuthenticated) {
      userStore.setReturnTo();
    }
  }, [isAuthenticated, userStore]);

  return (
    <Route
      {...rest}
      render={(routeProps) => (isAuthenticated ? <Component {...routeProps} /> : <Redirect to={routes.signInPath()} />)}
    />
  );
});

export default PrivateRoute;
