import styled from '@emotion/styled';
import { Button as AntButton } from 'antd';

export const FiltersContainer = styled.div`
  .top-left {
    display: flex;
    flex-direction: row;
    flex-basis: 1;
    align-items: center;

    .header {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      margin: 0;
    }

    .top-filters {
      display: flex;
      flex-direction: row;

      .top-filter {
        margin-left: 10px;
        margin-bottom: 0;
      }
    }
  }

  .top-right {
    display: flex;
    flex-direction: row;
    flex-basis: 1;
  }
`;

export const ApplyFiltersButton = styled(AntButton)`
  margin-left: 10px;
`;
