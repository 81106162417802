import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';
import { ReactNode } from 'react';

import { FilterPatternName } from './General/shared/GeneralWorkspace/collections';

// Visibility is an integer representing 3-digit binary
//     0            0           0
// visibility   filtering    sorting
export enum ColumnVisibility {
  Visible = 7, // 1 1 1
  NotSortable = 6, // 1 1 0
  NotFilterable = 5, // 1 0 1
  NotInteractable = 4, // 1 0 0
  OnlyFilterable = 3, // 0 1 0
  Hidden = 0, // 0 0 0
}

export type Decorator<T> = (item: T) => ReactNode;

export type CellWrapperProps =
  | React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  | undefined;

export interface NativeColumnSettings<T> {
  align?: 'left' | 'right' | 'center';
  decamelizedName?: string;
  decorator?: Decorator<T>;
  /** props for cell wrapper component */
  cellWrapperProps?: (data: T) => CellWrapperProps;
  /** fetch only listed fields for column if selectiveFieldsEnabled is true */
  decoratorSelectiveFields?: Array<keyof T>;
  enabled: boolean;
  /** works only if synthetic is true */
  skipAsterisk?: boolean;
  filterPrepareValues?: {
    wrap: (values: any[]) => any[];
    unwrap: (values: any[]) => any[];
  };
  multipleOptions?: boolean;
  nowrap?: boolean;
  options?: SelectOption[];
  sortBy?: string;
  /** used in tables AND filters **/
  title?: string;
  /** used in table headers only **/
  thTitle?: string | ReactNode;
  visibility?: ColumnVisibility;
  width?: number;
}
export interface SyntheticColumnSettings<T> {
  align?: 'left' | 'right' | 'center';
  enabled: boolean;
  decamelizedName?: string;
  nowrap?: boolean;
  skipAsterisk?: boolean;
  sortBy?: string;
  render: Decorator<T>;
  /** props for cell wrapper component */
  cellWrapperProps?: (data: T) => CellWrapperProps;
  /** fetch only listed fields for column if selectiveFieldsEnabled is true */
  renderSelectiveFields?: Array<keyof T>;
  /** used in tables AND filters **/
  title: string;
  /** used in table headers only **/
  thTitle?: string | ReactNode;
  visibility?: ColumnVisibility.Visible | ColumnVisibility.Hidden;
  width?: number;
}

export type ColumnSettings<T> =
  | ({
      /** Column which is not bound to any existing dataset attribute. */
      synthetic?: false | undefined;
    } & NativeColumnSettings<T>)
  | ({
      /** Column which is not bound to any existing dataset attribute.
       * The column label will have * at the end.
       */
      synthetic: true;
    } & SyntheticColumnSettings<T>);

export interface ColumnsMap<T> {
  [key: string]: ColumnSettings<T>;
}

export interface ExportColumnSettings {
  title: string;
  enabled: boolean;
}

export interface ExportColumnsMap {
  [key: string]: ExportColumnSettings;
}

export interface FilterSettings {
  label: string;
  attribute: string;
  filters: {
    pattern: FilterPatternName;
    options: { label: string; value: string }[];
  }[];
}

interface BulkActionBase {
  disabled?: boolean;
  name: string;
  label: string;
  skipConfirm?: boolean;
  tooltip?: string;
}

interface BulkActionSync<T> extends BulkActionBase {
  // Handler must return promise with either true or false
  // to inform if action successfully completed or not
  async?: false;
  handler: (records: T[]) => Promise<boolean> | void | Promise<void>;
}

interface BulkActionAsync<T> extends BulkActionBase {
  // Handler must return background job_id,
  // which will be used to track the job status
  async: true;
  handler: (records: T[]) => Promise<string>;
}

export type BulkAction<T> = BulkActionSync<T> | BulkActionAsync<T>;

const queryNamesBase = {
  II: 'workspacesIncomingInvoices',
  OI: 'workspacesOutcomingInvoices',
  QC: 'workspacesQualityControls',
  PR: 'workspacesProviders',
  SH: 'workspacesShippings',
  HsCodes: 'workspacesHsCodes',
  SU: 'workspacesStorages',
  BA: 'workspacesBillingAccounts',
  SA: 'workspacesShippingAddresses',
  PI: 'workspacesPayments',
  PZ: 'workspacesPayouts',
  RF: 'workspacesRefunds',
  OrganizationBalances: 'workspacesOrganizationBalances',
  Organization: 'workspacesOrganizations',
  D: 'workspacesDeals',
  Person: 'workspacesPersons',
  E: 'workspacesEnquiries',
  F: 'workspacesFrameworkBatches',
  J: 'workspacesJobs',
  JO: 'workspacesJobOffers',
  CL: 'workspacesClaims',
  BP: 'workspacesBankPayments',
  BPDraft: 'workspacesBankPaymentsDraft',
  PO: 'workspacesProviderOrders',
  User: 'workspacesUsers',
  RfqPartners: 'workspacesRfqPartners',
  RfqOffers: 'workspacesRfqOffers',
  Rfqs: 'workspacesRfqs',
  Tasks: 'workspacesTasks',
  DraftingDealParts: 'workspacesDraftingDealParts',
  DraftingEnquiryParts: 'workspacesDraftingEnquiryParts',
  MessageSubscriptions: 'workspacesSubscriptions',
  LI: 'workspacesLogisticInvoices',
  Messages: 'workspacesMessages',
  Coupons: 'workspacesCoupons',
  ProductionScheduleItems: 'workspacesProductionScheduleItems',
};

const queryNameByRegion: Record<Regions, Record<string, string>> = {
  [Regions.EU]: {
    Coupons: 'workspacesCoupons',
  },
  [Regions.UK]: {
    Coupons: 'workspacesEnquiryPromotions',
  },
  [Regions.TR]: {
    Coupons: 'workspacesEnquiryPromotions',
  },
};

export const QueryName = { ...queryNamesBase, ...queryNameByRegion[CURRENT_REGION] };

export type QueryNameKey = (typeof QueryName)[keyof typeof QueryName];

export interface SelectOption {
  label: string;
  value: string;
}
