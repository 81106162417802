import { BillingAccountsTypeEnumType, VatValidationStatusEnumType } from '__generated__/types';
import { gql } from 'apollo-boost';
import { BillingAccount, BillingAccountType } from 'interfaces/graphql';
import { BILLING_ACCOUNT_FRAGMENT } from 'utils/graphql/fragments';

interface BillingAccountInput {
  id?: string;
  type: BillingAccountsTypeEnumType;
  accountType: BillingAccountType;
  datevId?: string | null;
  intacctId?: string | null;
  name: string;
  country?: string | null;
  zip?: string | null;
  city?: string | null;
  address?: string | null;
  vatId?: string | null;
  taxId?: string | null;
  vatValidationStatus?: VatValidationStatusEnumType | null;
  isDefault?: boolean;
  personId?: number;
  vatRegistered?: boolean;
  vatRegistrationCountry?: string | null;
}

export interface BillingAccountsUpdateInput {
  id: string;
  attributes: BillingAccountInput;
}

export interface BillingAccountsUpdateResponse {
  billingAccountsUpdate: {
    billingAccount: BillingAccount;
  };
}

export const BILLING_ACCOUNTS_UPDATE = gql`
  mutation billingAccountsUpdate($id: ID!, $attributes: OmsInputsBillingAccountType!) {
    billingAccountsUpdate(input: { id: $id, attributes: $attributes }) {
      billingAccount {
        ...billingAccountFragment
      }
    }
  }
  ${BILLING_ACCOUNT_FRAGMENT}
`;

export interface BillingAccountsCreateInput {
  attributes: BillingAccountInput;
}

export interface BillingAccountsCreateResponse {
  billingAccountsCreate: {
    billingAccount: BillingAccount;
  };
}

export const BILLING_ACCOUNTS_CREATE = gql`
  mutation billingAccountsCreate($attributes: OmsInputsBillingAccountType!) {
    billingAccountsCreate(input: { attributes: $attributes }) {
      billingAccount {
        ...billingAccountFragment
      }
    }
  }
  ${BILLING_ACCOUNT_FRAGMENT}
`;

export interface BillingAccountsDeleteResponse {
  billingAccountsDelete: {
    billingAccount: BillingAccount;
  };
}

export const BILLING_ACCOUNTS_DELETE = gql`
  mutation billingAccountsDelete($id: ID!) {
    billingAccountsDelete(input: { id: $id }) {
      billingAccount {
        ...billingAccountFragment
      }
    }
  }
  ${BILLING_ACCOUNT_FRAGMENT}
`;

export const BILLING_ACCOUNTS_UPDATE_CASCADE = gql`
  mutation billingAccountsUpdateCascade($id: ID!, $attributes: OmsInputsBillingAccountUpdateCascadeType!) {
    billingAccountsUpdateCascade(input: { id: $id, attributes: $attributes }) {
      billingAccount {
        ...billingAccountFragment
      }
    }
  }
  ${BILLING_ACCOUNT_FRAGMENT}
`;
