import { Select, SelectProps } from 'antd';
import clsx from 'clsx';
import React, { FC, KeyboardEvent, useCallback } from 'react';
import { ALLOWED_CHAR_EMAIL, ALLOWED_PASTE_EMAIL, EMAIL_REGEXP, EMAIL_TEMPLATES_REGEXP } from 'validators/email';

import s from './EmailsField.module.less';

interface Props extends SelectProps<string[]> {
  disabled?: boolean;
  value?: string[];
  onChange?: (value: string[]) => void;
  onInputChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
  stringType?: boolean;
  showDropdown?: boolean;
  withPlaceholders?: boolean;
}

const tagClassName = 'ant-select-selection-item-content';
const ALLOWED_KEYS = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter'];
const SEPARATOR = ' ';
const separatorPattern = new RegExp(SEPARATOR, 'g');
const clearInput = (value = '') => value.replace(separatorPattern, '');

export const EmailsField: FC<Props> = (props) => {
  const {
    className,
    placeholder,
    value,
    disabled,
    popupClassName,
    onChange,
    onInputChange,
    onSearch,
    showDropdown = false,
    withPlaceholders = false,
    ...rest
  } = props;

  const [inputValue, setInputValue] = React.useState<string>('');

  const emailRegex = withPlaceholders ? EMAIL_TEMPLATES_REGEXP : EMAIL_REGEXP;

  const handleSearch: NonNullable<SelectProps['onSearch']> = useCallback(
    (value) => {
      setInputValue(value);
      onSearch?.(value);
    },
    [onSearch],
  );

  const handleInputChange = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const input = e.target as HTMLInputElement;

      // Confirm selection by removing and adding blur
      if (EMAIL_REGEXP.test(input.value) && e.key === SEPARATOR) {
        input.blur();

        requestAnimationFrame(() => {
          input.focus();
        });
      }

      const clearedValue = clearInput(input.value);

      onInputChange?.(clearedValue);
    },
    [onInputChange],
  );

  const handleChange = useCallback(
    (values: string[]) => {
      setInputValue('');

      if (!onChange) return;

      const clearedValues = withPlaceholders ? values : values.map(clearInput);
      const correctValues = clearedValues.filter((item) => emailRegex.test(item));

      onChange(correctValues);
    },
    [emailRegex, onChange, withPlaceholders],
  );

  // Click on tag to edit it.
  const handleClick = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      const targetClassName = (event.target as HTMLElement)?.className;
      const targetText = (event.target as HTMLElement)?.textContent;

      if (targetClassName === tagClassName && targetText) {
        const restEmails = value?.filter((email) => email !== targetText) || [];
        onChange?.(restEmails);
        setInputValue(targetText);
        onInputChange?.(targetText);
      }
    },
    [onChange, onInputChange, value],
  );

  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    // Some hotkey
    if (e.ctrlKey || e.metaKey) {
      return;
    }

    if (!ALLOWED_CHAR_EMAIL.test(e.key) && !ALLOWED_KEYS.includes(e.key)) {
      e.preventDefault();
    }
  }, []);

  const handlePaste = useCallback((event: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = event.clipboardData.getData('text');

    if (!ALLOWED_PASTE_EMAIL.test(pasteData)) {
      event.preventDefault();
    }
  }, []);

  return (
    <div className={clsx(s.wrapper, className)} onPaste={handlePaste}>
      <Select
        disabled={disabled}
        placeholder={placeholder}
        mode="tags"
        value={[...new Set(value)]}
        className={s.select}
        popupClassName={showDropdown ? popupClassName : clsx(s.hideDropdown, popupClassName)}
        onChange={handleChange}
        onSearch={handleSearch}
        searchValue={inputValue}
        showSearch={false}
        onKeyUp={handleInputChange}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
        {...rest}
      />
    </div>
  );
};
