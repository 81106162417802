const imageExtensions = ['png', 'jpg', 'jpeg', 'tiff', 'bmp'];
const pdfExtension = 'pdf';

export const getFileType = (name: string) => {
  const extension = name.split('.').pop();

  if (!extension) {
    return {
      isPreviewable: false,
      type: 'other',
      isPDF: false,
      isImage: false,
    };
  }

  if (imageExtensions.includes(extension)) {
    return {
      isPreviewable: true,
      type: 'image',
      isPDF: false,
      isImage: true,
    };
  }

  if (extension === pdfExtension) {
    return {
      isPreviewable: true,
      type: 'pdf',
      isPDF: true,
      isImage: false,
    };
  }

  return {
    isPreviewable: false,
    type: 'other',
    isPDF: false,
    isImage: false,
  };
};
