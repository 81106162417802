import { CURRENT_REGION } from './config';
import { Regions } from './types';

const IS_EU = CURRENT_REGION === Regions.EU;
const IS_UK = CURRENT_REGION === Regions.UK;
const IS_TR = CURRENT_REGION === Regions.TR;

// TODO: Remove this flag once TPO is released in CA https://xometry.atlassian.net/browse/XECA-749.
export const TPO_RELEASED = false;

export const HEADER_CURRENCY_ENABLED = IS_UK || IS_TR;
export const DATEV_ACCOUNTANT_SYSTEM_ENABLED = IS_EU;
export const LOYALTY_PROGRAM_ENABLED = !IS_TR;
export const VAT_EXEMPT_ENABLED = IS_TR || IS_UK;
/**
 * In the EU we used to have logic that all EU Billing accounts should have VAT BA. And Foreign Billing Accounts are having TAX_ID. The difference is that second is not a subject of our validation.
 * In UK to keep the same structure we need to change how we determine who is “Foreign”
 * https://xometry.atlassian.net/browse/XEUK-414
 */
export const SHIPMENT_ORDER_ENABLED = IS_EU || IS_TR;
export const SHIPMENT_AUTO_ORDER_ENABLED = IS_EU;
export const SHIPMENT_TRACKING_ENABLED = IS_EU;
export const ENQUIRIES_CUSTOM_VAT_ENABLED = IS_TR;
export const TEMP_CONTENT_FORM_NO_CURRENCY = IS_TR;
export const TEMP_CONTENT_FORM_DISABLED_CASE_LINK_RULES = IS_TR;
export const II_POSITIONS_NEED_CURRENCY = IS_TR || IS_UK;
export const CAN_EDIT_CURRENCY = IS_TR;
export const WITH_PREFERRED_LANGUAGE = IS_TR;
export const HAS_NOT_LOST_PRESET = IS_EU;
export const SUPPLIERS_WITH_MANUAL_VALUE = IS_TR;
export const CAN_CREATE_BP_IN_II = IS_EU;
export const CAN_CREATE_BP_IN_PZ = IS_EU;
export const RETURN_INVOICE_INSTEAD_OF_CREDIT_NOTE = IS_TR;
export const IS_CURRENCY_CHANGE_PROHIBITED_IN_PO = IS_EU || IS_UK;
export const TRANSFER_DEAL_TO_PERSON_ENABLED = IS_EU;
export const DDP_SHIPPING_BASIS_ENABLED = IS_UK;
export const DATEV_COMMENT_SHOW = IS_EU;
export const SENDING_SMS_VAKIFBANK_ENABLED = IS_TR;
/** https://xometry.atlassian.net/browse/XEFIN-229 */
export const NOT_VAT_PAYER_ENABLED = IS_EU;
export const TAGS_MAPPING_RFQ_ONLY = IS_EU;
export const PART_RESTART_WORKFLOW_ENABLED = IS_EU;
export const PUSH_ENQUIRY_TO_EU_FOR_RFQ_ENABLED = IS_TR || IS_UK;
export const UPDATE_XOM_SALES_QUOTE_ENABLED = IS_EU;
export const BULK_ACTIONS_PART_RESTART_WORKFLOW_ENABLED = IS_UK;
export const LOGISTIC_INVOICES_ENABLED = IS_EU;

// DATEV VS INTACCT
export const DATEV_ENABLED = IS_EU;
export const INTACCT_ENABLED = IS_UK || IS_TR;
export const CUSTOM_IO_NAME_ENABLED = IS_TR;

export const BANK_CODE_REQUIRED = !IS_TR;

export const XOMETRY_VAT_ENABLED = IS_EU || IS_UK;
export const SACHKONTO_VAT_RATE_VALIDATION_ENABLED = IS_EU;
/** @deprecated Don't need anymore (Eugene Balashov). Delete after remove the old deal header */
export const CUSTOMER_ORDER_PROGRESS_ENABLED = IS_TR;
export const HAVE_INVOICE_BATCH_EXPORTS = IS_EU;
export const HAS_ACCOUNTING_NAME = IS_EU || IS_UK;
export const HAS_INTACCT_ENABLED = IS_UK;
export const TAX_ID_IS_MAIN_TAX_FIELD = IS_TR || IS_UK;
export const VAT_VALIDATION_ENABLED = IS_EU;
export const SELF_BILLING_ENABLED = IS_EU;
export const MICROJUMP_ENABLED = IS_TR;
export const REJECT_OI_ENABLED = IS_TR;
export const PRIVATE_PERSON_TAX_ID_ENABLED = IS_TR;

export const SHOW_PROVIDER_CURRENCY_CHANGER = IS_TR;

/** https://xometry.atlassian.net/browse/XETW-1300 */
export const SHOW_ORIGINAL_SHIPPING_OPTION_PRICE = !IS_EU;
