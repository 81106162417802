import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type MessengerTemplateCategoryFragment = { id: string, title: string, channels: Array<Types.TemplateChannelEnum>, templatesGroups: Array<{ id: string, constantName?: string | null, name?: string | null, direction: Types.TemplateDirectionEnum, templates: Array<{ id: string, locale: Types.TemplateLocaleEnum }> }> };

export const MessengerTemplateCategoryFragmentDoc = gql`
    fragment messengerTemplateCategory on TemplateCategory {
  id
  title
  channels
  templatesGroups {
    id
    constantName
    name
    direction
    templates {
      id
      locale
    }
  }
}
    `;