import { TeamPage, TeamsPage } from 'pages';
import React from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'routes';

export const TeamsController = () => (
  <>
    <Route path={routes.teamsPath()} component={TeamsPage} exact />
    <Route path={routes.teamPath(':id')} component={TeamPage} exact />
  </>
);
