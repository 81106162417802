import { useApolloClient } from '@apollo/react-hooks';
import { TEventBusEventCallback } from '@xometry/event-bus';
import {
  EMessengerChatSourceType,
  EMessengerEventBusEvents,
  IEventBusMessengerEvents,
  MessengerEventBus,
  TEventBusMessengerRedirectToSourcePageEventPayload,
} from '@xometry/ui';
import { AppContext } from 'contexts/AppContext';
import { QMessengerCounter } from 'interfaces/graphql/messengerCounter';
import { cloneDeep } from 'lodash-es';
import { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { routesApi } from 'routes/api';
import { MessengerCounterDocument } from 'utils/graphql/queries/__generated__/messengerCounter';

import useDownload, { useGroupDownload } from './useDownload';

const getRouteBySource = ({
  dealId,
  sourceId,
  sourceType,
  parentId,
}: TEventBusMessengerRedirectToSourcePageEventPayload): string | undefined => {
  if (
    !dealId &&
    ![
      EMessengerChatSourceType.CUSTOMER,
      EMessengerChatSourceType.PARTNER,
      EMessengerChatSourceType.MASTER_SHIPPING,
      EMessengerChatSourceType.BILLING_ACCOUNT,
    ].includes(sourceType)
  ) {
    return;
  }

  const dealPath = routes.dealPath(String(dealId));

  if (!sourceType) {
    return dealPath;
  }

  if (!sourceId) return;

  const routesMap: Record<EMessengerChatSourceType, string | undefined> = {
    [EMessengerChatSourceType.CUSTOMER]: routes.personPath(sourceId),
    [EMessengerChatSourceType.PARTNER]: routes.providerPath(sourceId),
    [EMessengerChatSourceType.DEAL]: dealPath,
    [EMessengerChatSourceType.QUALITY_CONTROL]: routes.editDealQualityControlPath(String(dealId), sourceId),
    [EMessengerChatSourceType.ORDER_CONFIRMATION]: routes.orderConfirmationPath(String(dealId)),
    [EMessengerChatSourceType.PROVIDER_ORDER]: routes.editProviderOrderPath(String(dealId), sourceId),
    [EMessengerChatSourceType.PART]: routes.dealPartPath(String(dealId), sourceId),
    [EMessengerChatSourceType.CLAIM]: routes.editDealClaimPath(String(dealId), sourceId),
    [EMessengerChatSourceType.SHIPPING]: routes.editShippingPath(String(dealId), sourceId),
    [EMessengerChatSourceType.BILLING_ACCOUNT]: routes.billingAccountPath(sourceId),
    [EMessengerChatSourceType.MASTER_SHIPPING]: routes.editMasterShippingPath(sourceId),
    [EMessengerChatSourceType.STORAGE]: routes.editStoragePath(String(dealId), sourceId),
    [EMessengerChatSourceType.OUTCOMING_INVOICE]: routes.editOutcomingInvoicePath(String(dealId), sourceId),
    [EMessengerChatSourceType.INCOMING_INVOICE]: routes.editDealIncomingInvoicePath(String(dealId), sourceId),
    [EMessengerChatSourceType.PAYMENT]: routes.paymentPath(String(dealId), sourceId),
    [EMessengerChatSourceType.PAYOUT]: routes.payoutPath(String(dealId), sourceId),
    [EMessengerChatSourceType.JOB]: routes.jobPath(String(dealId), sourceId),
    [EMessengerChatSourceType.JOB_OFFER]: parentId ? routes.jobOfferPath(String(dealId), parentId, sourceId) : dealPath,
    [EMessengerChatSourceType.ENQUIRY]: routes.enquiryEditPath(String(dealId), sourceId),
    [EMessengerChatSourceType.ENQUIRY_PART]: routes.enquiryEditPath(String(dealId), sourceId),
    [EMessengerChatSourceType.ENQUIRY_RFQ]: undefined,
    [EMessengerChatSourceType.RFQ]: routes.rfqEditPath(String(dealId), sourceId),
    [EMessengerChatSourceType.RFQ_OFFER]: parentId ? routes.rfqOfferPath(String(dealId), parentId, sourceId) : dealPath,
  };

  return routesMap[sourceType];
};

export const useListenCommonMessengerEvents = () => {
  const { messengerEventBus } = useContext(AppContext);
  const history = useHistory();
  const apolloClient = useApolloClient();
  const downloader = useDownload();
  const { download: groupDownload } = useGroupDownload();

  const handleRedirectToSourcePage: TEventBusEventCallback<
    IEventBusMessengerEvents,
    EMessengerEventBusEvents.REDIRECT_TO_SOURCE_PAGE
  > = useCallback(
    (messageSource) => {
      const route = getRouteBySource(messageSource);

      if (route) {
        history.push(route);
      }
    },
    [history],
  );

  const handleDownload: TEventBusEventCallback<IEventBusMessengerEvents, EMessengerEventBusEvents.DOWNLOAD_FILE> =
    useCallback(
      async ({ fileId, fileName, onSuccess, onError }) => {
        try {
          await downloader(routesApi.attachedFileDownloadPath(fileId), fileName);

          if (typeof onSuccess === 'function') {
            onSuccess();
          }
        } catch {
          if (typeof onError === 'function') {
            onError();
          }
        }
      },
      [downloader],
    );

  const handleDownloadGroupFiles: TEventBusEventCallback<
    IEventBusMessengerEvents,
    EMessengerEventBusEvents.DOWNLOAD_FILES
  > = useCallback(
    async ({ uuidIds, fileName, onSuccess, onError }) => {
      try {
        await groupDownload(uuidIds, fileName);

        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      } catch {
        if (typeof onError === 'function') {
          onError();
        }
      }
    },
    [groupDownload],
  );

  const handleSetCounters: TEventBusEventCallback<IEventBusMessengerEvents, EMessengerEventBusEvents.SET_COUNTER> =
    useCallback(
      (newCounter) => {
        // https://github.com/apollographql/apollo-client/issues/3909#issuecomment-449363099
        const queryData = cloneDeep(apolloClient.readQuery<QMessengerCounter>({ query: MessengerCounterDocument }));

        apolloClient.writeQuery<QMessengerCounter>({
          query: MessengerCounterDocument,
          data: { messengerCounter: { ...queryData?.messengerCounter, ...newCounter } },
        });
      },
      [apolloClient],
    );

  const addEventListeners = useCallback(
    (eb: MessengerEventBus) => {
      eb.addEventListener(EMessengerEventBusEvents.REDIRECT_TO_SOURCE_PAGE, handleRedirectToSourcePage);
      eb.addEventListener(EMessengerEventBusEvents.DOWNLOAD_FILE, handleDownload);
      eb.addEventListener(EMessengerEventBusEvents.DOWNLOAD_FILES, handleDownloadGroupFiles);
      eb.addEventListener(EMessengerEventBusEvents.SET_COUNTER, handleSetCounters);
    },
    [handleDownload, handleDownloadGroupFiles, handleRedirectToSourcePage, handleSetCounters],
  );

  const removeEventListeners = useCallback(
    (eb: MessengerEventBus) => {
      eb.removeEventListener(EMessengerEventBusEvents.REDIRECT_TO_SOURCE_PAGE, handleRedirectToSourcePage);
      eb.removeEventListener(EMessengerEventBusEvents.DOWNLOAD_FILE, handleDownload);
      eb.removeEventListener(EMessengerEventBusEvents.DOWNLOAD_FILES, handleDownloadGroupFiles);
      eb.removeEventListener(EMessengerEventBusEvents.SET_COUNTER, handleSetCounters);
    },
    [handleDownload, handleDownloadGroupFiles, handleRedirectToSourcePage, handleSetCounters],
  );

  useEffect(() => {
    if (messengerEventBus) {
      addEventListeners(messengerEventBus);

      return () => {
        removeEventListeners(messengerEventBus);
      };
    }
  }, [addEventListeners, messengerEventBus, removeEventListeners]);
};
