import { getStructuredItems } from 'components/Deal/Sidebar/Left/Items/utils';
import {
  IDealSidebarItem,
  ObjectType,
  SidebarItemCL,
  SidebarItemDL,
  SidebarItemE,
  SidebarItemII,
  SidebarItemJ,
  SidebarItemOC,
  SidebarItemOI,
  SidebarItemP,
  SidebarItemPZ,
  SidebarItemQ,
  SidebarItemQC,
  SidebarItemRFQ,
  SidebarItemSH,
  SidebarItemSU,
  SortOptions,
} from 'interfaces';
import { DealObjectList, DealObjectListKeys } from 'interfaces/graphql';
import { each, isEqual, orderBy, reduce } from 'lodash-es';
import { action, computed, observable } from 'mobx';
import { oiName } from 'stores/Deal/OutInvoice/utils';
import { sortByType } from 'stores/Deal/utils';
import FetchableStore from 'stores/shared/FetchableStore';
import { getInvoiceShortName } from 'utils/deal/invoiceName';

import { defaultObjectsFilter } from './constants';
import { getFilterPresetByObjects } from './helpers';

export interface ObjectTypes {
  canceled?: boolean;
  creditNote?: boolean;
  children?: ObjectTypes[];
  id: number;
  isRefund?: boolean;
  objectType: ObjectType;
  createdAt?: string;
  updatedAt?: string;
  name: string;
  providerOrderId?: string | number;
  refInvoice?: {
    id: number;
    number: number | string;
    uiNumber?: string;
  };
  samplesMainProviderOrderId?: string;
  samplesProviderOrder?: ObjectTypes;
  sourceId?: number | string;
  sourceType?: string;
  state?: string;
  visible: boolean;
}

export class DealSidebarStore extends FetchableStore {
  refetch = () => Promise.resolve();

  @observable sortedBy: SortOptions = (localStorage.getItem('sidebarSort') as SortOptions) || SortOptions.TYPE_DESC;

  @observable selectedObjectTypes = localStorage.getItem('selectedObjectTypes')
    ? (JSON.parse(localStorage.getItem('selectedObjectTypes') as string) as Record<string, boolean>)
    : defaultObjectsFilter;

  @observable items: IDealSidebarItem[] = [];
  @observable v2items: ObjectTypes[] = [];
  @observable mixedItems: IDealSidebarItem[] = [];
  @observable dealObjectType = '';

  @action setV2Items = (dealObjectList: DealObjectList) => {
    let showOnlyOrderedParts = true;
    const pdId = dealObjectList.deal.pipedriveId;
    const result: ObjectTypes[] = [];

    each(Object.keys(dealObjectList), (objectType) => {
      switch (objectType) {
        case DealObjectListKeys.E: {
          const object = dealObjectList[DealObjectListKeys.E];

          if (object?.id) {
            const item: SidebarItemE = {
              id: object.id,
              currency: object.currency,
              objectType: ObjectType.E,
              createdAt: object.createdAt,
              updatedAt: object.updatedAt,
              name: object.number || `E-${pdId}-${object.id}`,
              visible: true,

              state: object.state,
              canceled: !!object.canceledAt,
              totalAmountNet: object.totalAmountNet,
              totalAmountBrut: object.totalAmountBrut,
            };

            result.push(item);
          }

          break;
        }

        case DealObjectListKeys.RFQ: {
          each(dealObjectList[DealObjectListKeys.RFQ], (rfq) => {
            result.push({
              id: rfq.id,
              objectType: ObjectType.RFQ,
              name: `RFQ-${pdId}-${rfq.id}`,
              state: rfq.state,
              publicationEnd: rfq.publicationEnd,
              visible: true,
            } as SidebarItemRFQ);
          });

          break;
        }

        case DealObjectListKeys.OC: {
          const object = dealObjectList[DealObjectListKeys.OC];

          if (object?.id) {
            showOnlyOrderedParts = false;

            const item: SidebarItemOC = {
              id: object.id,
              currency: object.currency,
              objectType: ObjectType.OC,
              createdAt: object.createdAt,
              updatedAt: object.updatedAt,
              name: object.number || `OC-${pdId}`,
              visible: true,

              state: object.state,
              publishedAt: object.publishedAt || undefined,
              totalAmountNet: object.totalAmountNet,
              totalAmountBrut: object.totalAmountBrut,
            };
            result.push(item);
          }

          break;
        }

        case DealObjectListKeys.Q:
          each(dealObjectList[DealObjectListKeys.Q], (quotation) => {
            result.push({
              id: quotation.id,
              objectType: ObjectType.Q,
              createdAt: quotation.createdAt,
              updatedAt: quotation.updatedAt,
              name: `Q-${pdId}-${quotation.id}`,
              visible: true,
            } as SidebarItemQ);
          });
          break;

        case DealObjectListKeys.P:
          each(dealObjectList[DealObjectListKeys.P], (payment) => {
            const prefix = payment.isRefund ? 'RF' : 'PI';

            result.push({
              amountBrut: payment.amountBrut,
              amountNet: payment.amountNet,
              createdAt: payment.createdAt,
              currency: payment.currency,
              id: payment.id,
              isRefund: payment.isRefund,
              methodType: payment.methodType,
              name: payment.number || `${prefix}-${pdId}-${payment.id}`,
              objectType: ObjectType.Payment,
              paidAt: payment.paidAt,
              positions: payment.positions,
              sourceId: payment.sourceId,
              sourceType: payment.sourceType,
              state: payment.state,
              transactionType: payment.transactionType,
              updatedAt: payment.updatedAt,
              visible: true,
            } as SidebarItemP);
          });
          break;

        case DealObjectListKeys.J:
          each(dealObjectList[DealObjectListKeys.J], (job) => {
            result.push({
              id: job.id,
              objectType: ObjectType.J,
              createdAt: job.createdAt,
              updatedAt: job.updatedAt,
              name: job.number || `J-${pdId}-${job.id}`,
              visible: true,

              publicationEnd: job.publicationEnd,
              state: job.state,
              value: job.value,
              valueCurrency: job.valueCurrency,
              providerOrder: job.providerOrder,
            } as SidebarItemJ);
          });
          break;

        case DealObjectListKeys.PO: {
          const providerOrders = dealObjectList[DealObjectListKeys.PO];

          each(providerOrders, (po) => {
            const item = {
              deadline: po.deadline,
              id: Number(po.id),
              objectType: ObjectType.PO,
              createdAt: po.createdAt,
              updatedAt: po.updatedAt,
              name: po.number || `PO-${pdId}-${po.id}`,
              visible: true,

              state: po.state,
              fullyPaidAt: po.fullyPaidAt,
              conformityCheck: po.conformityCheck,
              partsValueNetto: po.partsValueNetto,
              servicesValueNetto: po.servicesValueNetto,
              currency: po.currency,

              samplesMainProviderOrderId: po.samplesMainProviderOrderId,
              samplesPoType: po.samplesPoType,
              samplesConfirmationStatus: po.samplesConfirmationStatus,
            };
            result.push(item);
          });
          break;
        }

        case DealObjectListKeys.DL:
          each(dealObjectList[DealObjectListKeys.DL], (dl) => {
            result.push({
              id: dl.id,
              objectType: ObjectType.DL,
              createdAt: dl.createdAt,
              updatedAt: dl.updatedAt,
              name: `DL-${pdId}-${dl.id}`,
              visible: true,

              url: dl.url,
              providerOrderId: dl.providerOrder?.id,
              state: dl.state,
              direction: dl.direction,
            } as SidebarItemDL);
          });
          break;

        case DealObjectListKeys.SH:
          each(dealObjectList[DealObjectListKeys.SH], (sh) => {
            result.push({
              id: sh.id,
              objectType: ObjectType.SH,
              createdAt: sh.createdAt,
              updatedAt: sh.updatedAt,
              name: sh.number || `SH-${pdId}-${sh.id}`,
              visible: true,

              state: sh.state,
              direction: sh.direction,
              receivedProcessingStatus: sh.receivedProcessingStatus,
              relatedProviderOrders: sh.relatedProviderOrders,
            } as SidebarItemSH);
          });
          break;

        case DealObjectListKeys.QC:
          each(dealObjectList[DealObjectListKeys.QC], (qc) => {
            result.push({
              id: qc.id,
              objectType: ObjectType.QC,
              createdAt: qc.createdAt,
              updatedAt: qc.updatedAt,
              name: qc.number || `QC-${pdId}-${qc.id}`,
              visible: true,

              state: qc.state,
              kind: qc.kind,
              relatedProviderOrders: qc.relatedProviderOrders,
              positions: qc.positions,
            } as SidebarItemQC);
          });
          break;

        case DealObjectListKeys.SU:
          each(dealObjectList[DealObjectListKeys.SU], (su) => {
            result.push({
              id: su.id,
              objectType: ObjectType.SU,
              createdAt: su.createdAt,
              updatedAt: su.updatedAt,
              name: su.number || `SU-${pdId}-${su.id}`,
              visible: true,

              storageType: su.storageType,
              processingState: su.processingState,
              quantityLeft: su.quantityLeft,
              quantityInitial: su.quantityInitial,
            } as SidebarItemSU);
          });
          break;

        case DealObjectListKeys.OI:
          each(dealObjectList[DealObjectListKeys.OI], (oi) => {
            result.push({
              id: oi.id,
              objectType: ObjectType.OI,
              createdAt: oi.createdAt,
              updatedAt: oi.updatedAt,
              currency: oi.currency,
              name: oi.uiNumber ?? oiName(oi.id, oi.number, oi.creditNote),
              visible: true,

              refInvoice: oi.refInvoice,
              isStorno: oi.isStorno,
              invoiceNeedFix: oi.invoiceNeedFix,
              invoiceReady: oi.invoiceReady,
              invoiceChecked: oi.invoiceChecked,
              creditNote: oi.creditNote,
              state: oi.state,
              paidAt: oi.paidAt,
              registeredAt: oi.registeredAt,
              totalAmountNetto: oi.totalAmountNetto,
              totalAmountBrutto: oi.totalAmountBrutto,
            } as SidebarItemOI);
          });
          break;

        case DealObjectListKeys.CL:
          each(dealObjectList[DealObjectListKeys.CL], (cl) => {
            result.push({
              id: cl.id,
              objectType: ObjectType.CL,
              createdAt: cl.createdAt,
              updatedAt: cl.updatedAt,
              name: cl.number || cl.name,
              visible: true,

              state: cl.state,
            } as SidebarItemCL);
          });
          break;

        case DealObjectListKeys.PZ:
          each(dealObjectList[DealObjectListKeys.PZ], (p) => {
            result.push({
              id: Number(p.id),
              objectType: ObjectType.PZ,
              createdAt: p.createdAt,
              updatedAt: p.updatedAt,
              name: p.number,
              paidAt: p.paidAt,
              isRefund: p.isRefund,
              sourceId: p.sourceId,
              sourceType: p.sourceType,
              number: p.number,
              visible: true,
              amount: p.amount,
              amountMoney: p.amountMoney,
              state: p.state,
            } as SidebarItemPZ);
          });
          break;

        case DealObjectListKeys.II:
          each(dealObjectList[DealObjectListKeys.II], (ii) => {
            const name = getInvoiceShortName({ ...ii, number: String(ii.number) });

            result.push({
              id: Number(ii.id),
              objectType: ObjectType.II,
              createdAt: ii.createdAt,
              updatedAt: ii.updatedAt,
              name,
              visible: true,

              providerOrderId: ii.providerOrderId,
              refInvoice: ii.refInvoice,
              state: ii.state,
              paidAt: ii.paidAt,
              registeredAt: ii.registeredAt,
              number: ii.number,
              creditNote: ii.creditNote,
              isPartial: ii.isPartial,
              validity: ii.validity,
              totalAmountNetto: ii.totalAmountNetto,
              totalAmountNettoMoney: ii.totalAmountNettoMoney,
              totalAmountBrutto: ii.totalAmountBrutto,
              totalAmountBruttoMoney: ii.totalAmountBruttoMoney,
              selfBilling: ii.selfBilling,
            } as SidebarItemII);
          });
          break;

        default:
          break;
      }
    });

    this.v2items = result;
    this.filter();
    this.sortBy();
    this.rootStore.partsStore.showOnlyOrderedParts = showOnlyOrderedParts;
  };

  @action setSelectedObjectTypes = (values: Record<string, boolean>) => {
    this.selectedObjectTypes = values;
    localStorage.setItem('selectedObjectTypes', JSON.stringify(values));
  };

  @action filter() {
    const visibleTypes = reduce(
      this.selectedObjectTypes,
      (result, visible, objectType) => {
        if (visible) {
          result.push(objectType);

          return result;
        }

        return result;
      },
      [] as string[],
    );

    this.v2items = each(this.v2items, (item) => {
      let visible = visibleTypes.includes(item.objectType);

      if (visible && !this.selectedObjectTypes.canceled) {
        if (item.canceled !== undefined && item.canceled) {
          visible = false;
        } else {
          visible = item.state !== 'canceled';
        }
      }

      item.visible = visible;
    });
  }

  @action setDealObjectType(objectType: string) {
    this.dealObjectType = objectType;
  }

  @action sortBy(value?: string) {
    if (!value) {
      value = this.sortedBy;
    } else {
      this.sortedBy = value as SortOptions;
    }

    localStorage.setItem('sidebarSort', this.sortedBy);

    switch (value) {
      case SortOptions.UPDATED_ASC:
        this.v2items = orderBy(this.v2items, (item) => new Date(item.updatedAt || ''), ['asc']);
        break;
      case SortOptions.UPDATED_DESC:
        this.v2items = orderBy(this.v2items, (item) => new Date(item.updatedAt || ''), ['desc']);
        break;
      case SortOptions.CREATED_DESC:
        this.v2items = orderBy(this.v2items, (item) => new Date(item.createdAt || ''), ['desc']);
        break;
      case SortOptions.CREATED_ASC:
        this.v2items = orderBy(this.v2items, (item) => new Date(item.createdAt || ''), ['asc']);
        break;
      case SortOptions.TYPE_ASC:
        this.v2items = orderBy(this.v2items, (item) => sortByType(item.objectType, item.name), ['asc']);
        break;
      default:
        this.v2items = orderBy(this.v2items, (item) => sortByType(item.objectType, item.name), ['desc']);
    }

    this.v2items = orderBy(this.v2items, (item) => 'state' in item && item.state === 'canceled');
  }

  @computed get isFiltered() {
    if (!this.selectedObjectTypes) {
      return false;
    }

    return !isEqual(this.selectedObjectTypes, defaultObjectsFilter);
  }

  @computed get filterPreset() {
    return getFilterPresetByObjects(this.selectedObjectTypes);
  }

  @computed get structuredItems() {
    return getStructuredItems(this.v2items).filter((item) => item.visible);
  }
}
