import { TemplateDirectionEnum, TemplateSortOrderEnumType } from '__generated__/types';

import { TableSortOrderEnum } from './TemplatesPageTable.types';

export const DIRECTION_LABEL_MAP: Record<TemplateDirectionEnum, string> = {
  [TemplateDirectionEnum.Any]: 'Any',
  [TemplateDirectionEnum.X2c]: 'Customer',
  [TemplateDirectionEnum.X2p]: 'Partner',
  [TemplateDirectionEnum.X2x]: 'Internal',
};

export const SORT_ORDER_MAP: Record<TableSortOrderEnum, TemplateSortOrderEnumType> = {
  [TableSortOrderEnum.Ascend]: TemplateSortOrderEnumType.Asc,
  [TableSortOrderEnum.Descend]: TemplateSortOrderEnumType.Desc,
};
