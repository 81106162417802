import { IErpPermissionsGroup } from 'interfaces/graphql/erpPermission';
import { action, observable } from 'mobx';
import { ErpPermissionsDocument, ErpPermissionsQuery } from 'utils/graphql/queries/__generated__/erpPermissions';

import { RootStore } from './RootStore';

/**
 * Using for fetching and storing erp permissions
 * for use them in access error notifications.
 */
export class ErpPermissionsStore {
  rootStore: RootStore;

  @observable erpPermissions: IErpPermissionsGroup[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  fetchErpPermissions = () => {
    void this.rootStore.gql
      .query<ErpPermissionsQuery>({
        query: ErpPermissionsDocument,
      })
      .then((res) => {
        if (res.data?.erpPermissions) {
          this.setErpPermissions(res.data.erpPermissions);
        }
      });
  };

  @action setErpPermissions = (permissions: IErpPermissionsGroup[]) => {
    this.erpPermissions = permissions;
  };
}
