import { TemplateDirectionEnum } from '__generated__/types';
import { ReactComponent as PartnerIcon } from 'assets/images/icons/messenger/partner.svg';
import { ReactComponent as CustomerIcon } from 'assets/images/icons/messenger/person.svg';
import { ReactComponent as AnyIcon } from 'assets/images/icons/people.svg';
import { ReactComponent as InternalIcon } from 'assets/images/icons/team.svg';
import { DIRECTION_LABEL_MAP } from 'components/TemplatesPage/TemplatesPageTable/consts';
import React, { FC } from 'react';

import s from './TemplateDirectionLabel.module.less';

const DIRECTION_ICON_MAP: Record<
  TemplateDirectionEnum,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >
> = {
  [TemplateDirectionEnum.Any]: AnyIcon,
  [TemplateDirectionEnum.X2c]: CustomerIcon,
  [TemplateDirectionEnum.X2p]: PartnerIcon,
  [TemplateDirectionEnum.X2x]: InternalIcon,
};

export const TemplateDirectionLabel: FC<{ direction: TemplateDirectionEnum; hideLabel?: boolean }> = ({
  direction,
  hideLabel = false,
}) => {
  const label = DIRECTION_LABEL_MAP[direction];
  const Icon = DIRECTION_ICON_MAP[direction];

  return (
    <div className={s.directionLabel}>
      <Icon />
      {hideLabel ? null : <span>{label}</span>}
    </div>
  );
};
