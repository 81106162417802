export const ENV_CUSTOM_ENV = String(
  import.meta.env.REACT_APP_CUSTOM_NODE_ENV || process.env['REACT_APP_CUSTOM_NODE_ENV'] || 'development',
);
export const ENV_BASE_URL = String(import.meta.env.REACT_APP_BASE_URL || process.env['REACT_APP_BASE_URL'] || '');

/**
 * Get ENV variable.
 *
 * First it tries to get the variable from URL param.
 * If there's nothing in URL, then it tries to get the same param
 * from SessionStorage scoped to current BASE_URL.
 * If there's nothing in SessionStorage it returns the build-time value.
 *
 * In PRODUCTION mode it always returns build-time value.
 */
const getENVVariable = (name: string): string => {
  const realValue = String(import.meta.env[name] || process.env[name] || '');

  if (ENV_CUSTOM_ENV === 'production') {
    return realValue;
  }

  if (typeof window === 'undefined') {
    return realValue;
  }

  try {
    const URL = window.location.href || '';
    const storageKey = `${ENV_BASE_URL}__${name}`;

    const [, ...paramsList] = URL.split('?');
    const _params = new URLSearchParams((paramsList || []).join('?'));

    const URLParamValue = _params.get(name);

    if (typeof URLParamValue === 'string') {
      window.sessionStorage.setItem(storageKey, URLParamValue);

      return URLParamValue;
    }

    const sessionStorageParamValue = window.sessionStorage.getItem(storageKey);

    if (typeof sessionStorageParamValue === 'string') {
      return sessionStorageParamValue;
    }
  } catch (e: unknown) {
    console.error(e);
  }

  return realValue;
};

export const ENV_DEDICATED_STAGING = getENVVariable('REACT_APP_DEDICATED_STAGING') || 'false';

export const SENTRY_DSN = getENVVariable('REACT_APP_SENTRY_DSN');
export const RELEASE = getENVVariable('REACT_APP_SENTRY_RELEASE') || 'development';

export const ENV_API_ENDPOINT = getENVVariable('REACT_APP_API_ENDPOINT');
export const ENV_WS_ENDPOINT = getENVVariable('REACT_APP_WS_ENDPOINT');
export const ENV_MESSENGER_URL = getENVVariable('REACT_APP_MSG_ENDPOINT');
export const ENV_APP_REGION = getENVVariable('REACT_APP_APP_REGION') || 'EU';
export const ENV_SUPPORTED_LOCALES = getENVVariable('REACT_APP_SUPPORTED_LOCALES') || 'en';

export const ENV_APP_HOST_EU = getENVVariable('REACT_APP_APP_HOST_EU');
export const ENV_APP_HOST_UK = getENVVariable('REACT_APP_APP_HOST_UK');
export const ENV_APP_HOST_TR = getENVVariable('REACT_APP_APP_HOST_TR');

export const ENV_CUSTOMERS_APP_HOST_EU = getENVVariable('REACT_APP_CUSTOMERS_APP_HOST_EU');
export const ENV_CUSTOMERS_APP_HOST_UK = getENVVariable('REACT_APP_CUSTOMERS_APP_HOST_UK');
export const ENV_CUSTOMERS_APP_HOST_TR = getENVVariable('REACT_APP_CUSTOMERS_APP_HOST_TR');

export const ENV_PARTNERS_APP_HOST_EU = getENVVariable('REACT_APP_PARTNERS_APP_HOST_EU');
export const ENV_PARTNERS_APP_HOST_UK = getENVVariable('REACT_APP_PARTNERS_APP_HOST_UK');
export const ENV_PARTNERS_APP_HOST_TR = getENVVariable('REACT_APP_PARTNERS_APP_HOST_TR');
