import { ExportColumnsMap } from 'components/Workspaces/collections';
import { decamelize } from 'humps';
import { endsWith, has } from 'lodash-es';

import { Column } from './collections';
import { columnVisible } from './utils';

interface ColumnsMap {
  [key: string]: Column;
}

interface Props {
  columns: Column[];
  extraColumns?: ExportColumnsMap;
}

const addColumn = (obj: ColumnsMap, column: Column, forceEnable: boolean = false) => {
  const key = column.settings?.decamelizedName || decamelize(column.key);

  if (has(obj, key)) return;

  obj[key] = {
    ...column,
    key,
    title: column.title || key,
    enabled: forceEnable || column.enabled,
  };
};

export const generateExportColumns = ({ columns, extraColumns }: Props): Column[] => {
  const exportColumns = columns
    .filter((column) => columnVisible(column.settings?.visibility) && !column.field?.description?.includes('custom'))
    .reduce((mem, column) => {
      if (!column.synthetic) addColumn(mem, column);

      let additionalFields = column.synthetic
        ? column.settings?.renderSelectiveFields
        : column.settings?.decoratorSelectiveFields;

      // Don't export ...Cents for Money fields, because it duplicates value
      if (additionalFields && endsWith(column.key, 'Money')) {
        additionalFields = additionalFields.filter((f) => !endsWith(f.toString(), 'Cents'));
      }

      additionalFields?.forEach((field) => {
        const fieldColumn = columns.find((c) => c.key == field);

        // If current column enabled, enable all child columns too
        if (fieldColumn != null) addColumn(mem, fieldColumn, column.enabled);
      });

      return mem;
    }, {} as ColumnsMap);

  if (extraColumns != null) {
    Object.entries(extraColumns).forEach(([key, { title, enabled }]) => {
      const extraColumn = columns.find((c) => c.key == key);

      if (extraColumn == null) return;

      addColumn(exportColumns, { ...extraColumn, title, enabled });
    });
  }

  return Object.values(exportColumns).sort((a, b) => a.title.localeCompare(b.title));
};
