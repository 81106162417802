import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type MoneyFragment = { amount: string, amountCents: string, currencyCode: Types.CurrencyEnum };

export const MoneyFragmentDoc = gql`
    fragment money on Money {
  amount
  amountCents
  currencyCode
}
    `;