import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type ContactFragment = { id: number, firstName: string, lastName: string, email: string, position?: string | null, phone?: string | null, viber?: string | null, whatsapp?: string | null, telegram?: string | null, wechat?: string | null, preferredContactType?: string | null, openPassword?: string | null, disallowToPool?: boolean | null, verifiedAt?: string | null, verifyKey?: string | null };

export const ContactFragmentDoc = gql`
    fragment contact on ProviderContact {
  id
  firstName
  lastName
  email
  position
  phone
  viber
  whatsapp
  telegram
  wechat
  preferredContactType
  openPassword
  disallowToPool
  verifiedAt
  verifyKey
}
    `;