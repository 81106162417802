import { message, Space } from 'antd';
import { UploadProps } from 'antd/lib/upload/interface';
import { FileDownload } from 'components/UI/FileDownload';
import Spin from 'components/UI/Spin';
import { Uploader } from 'components/UI/Uploader';
import { filter } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { routesApi } from 'routes/api';
import { useStore } from 'stores/RootStore';

const MAX_UPLOAD_SIZE_IN_MB = 75;

export const defaultBeforeUpload = (file: { type: string; size: number }): boolean => {
  const isLt75M = file.size / 1024 / 1024 < MAX_UPLOAD_SIZE_IN_MB;

  if (!isLt75M) {
    void message.error(`Image must smaller than ${MAX_UPLOAD_SIZE_IN_MB}MB!`);
  }

  return isLt75M;
};

interface Props {
  beforeUpload?(file: { type: string; size: number }): boolean;
  fetchUrl: string;
  uploadUrl?: string;
  destroyUrl?: string;
  context?: string;
}

const AttachedFilesUploader = observer<Props>(
  ({ beforeUpload = defaultBeforeUpload, fetchUrl, uploadUrl, destroyUrl, context }) => {
    const { attachedFilesStore } = useStore();

    useEffect(() => {
      void attachedFilesStore.fetch(fetchUrl);
    }, [fetchUrl, attachedFilesStore]);

    if (!attachedFilesStore.fetchStatus.isFetched) {
      return <Spin size="large" />;
    }

    if (!uploadUrl) {
      return null;
    }

    const customRequest: UploadProps['customRequest'] = (params) => {
      if (uploadUrl == null) return;

      const formData = new FormData();
      formData.append('file', params.file);

      void attachedFilesStore.upload(uploadUrl, formData);
    };

    const onDestroyClick = (id: number) => {
      if (destroyUrl) {
        void attachedFilesStore.delete(`${destroyUrl}?attached_file_id=${id}`, id);
      }
    };

    const isUploading = attachedFilesStore.uploadStatus.isProcessing;

    const fileList = context ? filter(attachedFilesStore.attachments, { context }) : attachedFilesStore.attachments;
    const sortedFiles = fileList.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

    return (
      <Space direction="vertical" size="small" className="w-100">
        <Uploader
          loading={isUploading}
          showUploadList={false}
          customRequest={customRequest}
          beforeUpload={beforeUpload}
          multiple
        />
        <Space direction="vertical" size={4} className="w-100">
          {sortedFiles.map((file) => (
            <FileDownload
              key={file.id}
              id={file.id}
              url={routesApi.attachedFileDownloadPath(file.id)}
              name={file.name}
              createdAt={file.createdAt}
              open
              download
              onDelete={() => onDestroyClick(file.id)}
            />
          ))}
        </Space>
      </Space>
    );
  },
);

export default AttachedFilesUploader;
