import { routesApi } from 'routes/api';
import { useLazyFetch } from 'utils/hooks/useLazyFetch';

export interface AttachedFilePreviewData {
  name: string;
  bigUrl: string;
}

interface Response {
  payload: AttachedFilePreviewData;
}

interface Props {
  id: string | number | undefined;
}

export const useFilePreview = ({ id }: Props) => {
  const { data, error, loading, fetchData } = useLazyFetch<Response>();

  const preview = async () => {
    if (!id) {
      return;
    }

    return fetchData({
      url: routesApi.attachedFilePreviewPath(id),
      camelizeResponse: true,
    });
  };

  return {
    data,
    error,
    loading,
    preview,
  };
};
