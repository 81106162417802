import { Image } from 'antd';
import { Modal } from 'components/UI/Modal/styled';
import PdfViewer from 'components/UI/PdfViewer';
import { PdfViewerWrapper } from 'components/UI/Preview/Modal/styled';
import React from 'react';

import s from './AttachmentPreview.module.less';

interface Props {
  name?: string;
  url: string | undefined;
  isPDF?: boolean;
  modalVisible: boolean;
  closeModal: () => void;
}

export const AttachmentPreview: React.FC<Props> = (props) => {
  const { isPDF, url, name, modalVisible, closeModal } = props;

  return (
    <>
      {!isPDF && url && modalVisible && (
        <Image
          width={200}
          src={url}
          wrapperClassName={s.imageWrapper}
          preview={{
            visible: modalVisible,
            onVisibleChange: closeModal,
          }}
        />
      )}

      {isPDF && modalVisible && url && (
        <Modal className={s.modal} isFullPage title={name} open footer={null} onCancel={closeModal} destroyOnClose>
          <PdfViewerWrapper>
            <PdfViewer className={s.pdfViewer} src={url} />
          </PdfViewerWrapper>
        </Modal>
      )}
    </>
  );
};
