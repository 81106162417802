import * as PAGES from 'pages';
import { wait } from 'utils/promiseUtils';

export const preloadRoutes = async () => {
  const keys = Object.keys(PAGES) as Array<keyof typeof PAGES>;

  for (const key of keys) {
    const page = PAGES[key];

    if ('preload' in page) {
      // Wait a bit so we won't load everything at once
      await wait(500);

      page.preload();
    }
  }
};
