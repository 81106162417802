import { PlusOutlined } from '@ant-design/icons';
import { Spin, Upload, UploadProps } from 'antd';
import clsx from 'clsx';
import React from 'react';

import s from './Uploader.module.less';

interface Props extends UploadProps {
  label?: string;
  className?: string;
  loading?: boolean;
}

export const Uploader = (props: Props) => {
  const { label = 'Upload', className, loading, ...rest } = props;

  return (
    <Upload className={clsx(s.upload, className)} {...rest}>
      <div className={s.upload__button}>
        {loading ? (
          <Spin className={s.spin} />
        ) : (
          <div className={s.upload__buttonLabel}>
            <PlusOutlined />
            <div>{label}</div>
          </div>
        )}
      </div>
    </Upload>
  );
};
