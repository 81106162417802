import { AxiosError, AxiosRequestConfig } from 'axios';
import { useCallback, useState } from 'react';
import api, { camelizeKeys } from 'utils/axios';

interface FetchParams {
  url: string;
  options?: AxiosRequestConfig;
  onError?: (error: AxiosError) => void;
  camelizeResponse?: boolean;
}

/** Hook for lazy fetching data by axios. */
export const useLazyFetch = <Response, ErrorResponse = any>() => {
  const [data, setData] = useState<Response | null>(null);
  const [error, setError] = useState<AxiosError<ErrorResponse> | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async (params: FetchParams) => {
    const { camelizeResponse, url, options, onError } = params;
    setLoading(true);
    try {
      const response = await api.get<Response>(url, options);

      const responseData = camelizeResponse ? camelizeKeys(response.data) : response.data;
      setData(responseData);

      return responseData;
    } catch (error: unknown) {
      setError(error as AxiosError<ErrorResponse>);
      onError?.(error as AxiosError);

      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  return { fetchData, data, error, loading };
};
