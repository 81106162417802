import { HSCodeState } from 'components/Deal/shared/PartHsCodeForm/PartHsCodeForm.types';

import { MeasurementProtocols, PartRelatedObject } from './stores/PartsStore';

export enum LoadStatus {
  NotInit = 'notInitialized',
  IsLoading = 'isLoading',
  IsFinished = 'isFinished',
  Error = 'error',
}

export enum ServiceType {
  Packaging = 'packaging',
  Transport = 'transport',
  CustomClearance = 'custom_clearance',
  Coupon = 'coupon',
  Discount = 'discount',
  PrepayDiscount = 'discount_prepay',
  MinOrderCharge = 'min_order_charge',
  // Certificates = 'certificates',
  Certificates = 'cert_material',
  MeasurementProtocols = 'measurement_protocols',
  Service = 'service',
  Correction = 'correction',
  Material = 'material',
  Other = 'other',
  Parts = 'parts',
  Total = 'total',
  ImTools = 'im_tools',
}

export interface PartData {
  id: number;
  name: string;
  position?: number;
  isExpress: boolean;
  isPreQuote: boolean;
  isSamplesNeeded: boolean;
  mpNeeded?: MeasurementProtocols;
  internalComment?: string;

  preQuotedByLabel?: string;
  preQuotedComment?: string;

  samplesQuantity?: number;
  samplesComment?: string;

  material?: string;
  postProcessing?: string;
  customerNote?: string;
  productionRemark?: string;

  hsCode?: string;
  hsCodeName?: string;
  hsCodePurpose?: string | null;
  hsCodeState?: HSCodeState | null;
  hsCodeConfirmedBy?: {
    label: string;
    email: string;
  } | null;
  hsCodeConfirmedAt: string | null;

  relatedObjects?: PartRelatedObject[];
}

export enum ObjectType {
  Part = 'Deal::Part',
  Person = 'Person',
  Organization = 'Organization',
  J = 'Job',
  BP = 'BankPayment',
  JO = 'Job::Offer',
  OI = 'Deal::OutcomingInvoice',
  II = 'Deal::IncomingInvoice',
  PZ = 'Deal::Payout',
  Payment = 'Deal::Payment',
  PO = 'Deal::ProviderOrder',
  BA = 'BillingAccount',
  CL = 'Deal::Claim',
  OC = 'Deal::OrderConfirmation',
  DL = 'Deal::Delivery',
  RFQ = 'Rfq',
  RfqOffer = 'Rfq::Offer',
  CreditNote = 'Deal::CreditNote',
  E = 'Enquiry',
  EP = 'Enquiry::Part',
  D = 'Deal',
  SA = 'ShippingAddress',
  Event = 'Event',
  Q = 'Deal::Quotation',
  SH = 'Deal::Shipping',
  MSH = 'MasterShipping',
  SU = 'Deal::Storage',
  QC = 'Deal::QualityControl',
  QCP = 'Deal::QualityControl::Position',
  DealLot = 'Deal::Lot',
  PR = 'Provider',
  ProviderContact = 'Provider::Contact',
  User = 'User',
  DealBalanceRecord = 'DealBalanceRecord',
  OrganizationBalanceRecord = 'OrganizationBalanceRecord',
  FrameworkBatch = 'FrameworkBatch',
  HsCodeDataset = 'HsCodeDataset',
}

export interface PreloadedFile {
  id: number;
  name: string;
  _destroy?: boolean;
}

export interface Address {
  zip?: string | null;
  city?: string | null;
  address?: string | null;
  country?: string | null;
}

export interface Pagination {
  current: number;
  total: number;
  last: boolean;
}

export type InputType =
  | 'text'
  | 'number'
  | 'checkbox'
  | 'money'
  | 'instruction'
  | 'dropzone'
  | 'select'
  | 'separator'
  | 'date'
  | 'longtext'
  | 'approval'
  | 'claim_ref'
  | 'coupon_ref'
  | 'po_ref'
  | 'user_ref'
  | 'part_ref'
  | 'provider_ref'
  | 'event_ref'
  | 'zendesk_ticket_ref'
  | 'url';

export interface ICurrentlyViewing {
  name: string;
  time: string;
  views: number;
}

export interface ICachedXometryVatNumber {
  id: string;
  country: string;
  number: string;
  validation?: string | null;
  reverseCharge?: boolean;
  reverseChargeRemark?: string | null;
  isActive?: boolean;
}

export interface IFormXometryVatNumber {
  id: string;
  country: string;
  number: string;
  validation?: Date;
  reverseCharge?: boolean;
  reverseChargeRemark?: string | null;
  isActive?: boolean;
}
