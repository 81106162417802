import { WorkspacesRfqOffer } from '__generated__/types';
import { decisionStateMapping } from 'collections/rfqOffers';
import { dateHoursDiffFormat } from 'components/utils/formatters';
import moment from 'moment';
import React from 'react';

export const decisionStateDecorator = (rfqOffer: WorkspacesRfqOffer) => {
  const decisionState = rfqOffer.decisionState as keyof typeof decisionStateMapping;

  return (
    <>
      {decisionStateMapping[decisionState] && decisionState !== 'pending' ? (
        <>
          <b style={{ color: decisionStateMapping[decisionState].color }}>
            {decisionStateMapping[decisionState].label}
          </b>
          <br />
          {rfqOffer.decidedAt ? (
            <div className="m-t-s">{Math.round(dateHoursDiffFormat(rfqOffer.decidedAt, moment()))} hrs ago</div>
          ) : null}
        </>
      ) : (
        <>&mdash;</>
      )}
    </>
  );
};
