/* eslint-disable max-lines-per-function */
import { SortDirectionEnum } from '__generated__/types';
import { useQuery } from '@apollo/react-hooks';
import { NotAuthorized } from 'components/shared/NotAuthorized';
import { Notification, PageLoader } from 'components/UI';
import {
  BulkAction,
  ColumnsMap,
  ExportColumnsMap,
  QueryName,
  QueryNameKey,
  SyntheticColumnSettings,
} from 'components/Workspaces/collections';
import { HeaderDecorator } from 'components/Workspaces/General/shared/interfaces';
import humanizeString from 'humanize-string';
import { camelize, decamelize } from 'humps';
import { ExistingWorkspacePreset, WorkspacePreset } from 'interfaces/graphql/workspacePreset';
import { filter, findIndex, flatMap, includes, isEmpty, map, pickBy, sortBy } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import useAuthorizedQuery from 'utils/hooks/useAuthorizedQuery';

import {
  Column,
  ColumnHeaderDecorator,
  GraphQLField,
  GraphQLScalarType,
  IAttributesResponse,
  IDataItem,
  IPaginationParam,
  ISortParam,
  ITopFilter,
  PermissionsSettings,
  SyntheticColumn,
  WorkspaceGraphQLField,
  WorkspaceGraphQLObjectField,
  WorkspaceGraphQLScalarField,
} from './collections';
import { columnsWithMoney } from './columnsWithMoney';
import FiltersAndActions from './FiltersAndActions';
import { FilterItem, useFilters } from './FiltersStore';
import RecordsTable from './RecordsTable';
import { TableTop } from './RecordsTable/TableTop';
import { ErrorMessage, MainContainer, PageContainer, PageContent, PageHeader, TableWrapper } from './styled';
import { attributesQuery, columnVisible, filterSelectiveFields, objectQuery, ObjectQueryResponse } from './utils';
import withPageTitle from './withPageTitle';
import withPermissions from './withPermissions';
import withPresets, { IPresetAttributes, PresetOptionData } from './withPresets';

const DEFAULT_PAGE_SIZE = 100;

const defaultSort: ISortParam = { sortBy: 'id', direction: SortDirectionEnum.Desc };
const browserTimezoneOffset = new Date().getTimezoneOffset();

const convertField = (field: GraphQLField): WorkspaceGraphQLField => {
  let { type } = field;
  let list = false;

  while (type.name == null) {
    if (type.kind === 'LIST') list = true;

    type = type.ofType;
  }

  if (type.kind === 'SCALAR' || type.kind === 'ENUM') {
    return {
      ...field,
      type,
      list,
      enum: type.kind === 'ENUM',
    } as WorkspaceGraphQLScalarField;
  }

  return {
    ...field,
    type,
    list,
  } as WorkspaceGraphQLObjectField;
};

interface PropsWithoutRowExpansion {
  expandRows?: false;
  rowExpansion?: undefined;
}

interface PropsWithRowExpansion {
  expandRows: true;
  rowExpansion: React.ReactNode;
}

interface BaseProps {
  sortParams?: ISortParam[];
  bulkActions?: BulkAction<any>[];
  columnHeaderHeight?: number;
  columns?: ColumnsMap<any>;
  exportColumns?: ExportColumnsMap;
  forceSelectedRecords?: IDataItem[];
  forceTimezoneOffset?: number;
  header: string;
  headerDecorator?: HeaderDecorator;
  hidePresetFilters?: boolean;
  hideRowNumbers?: boolean;
  hideUndefinedColumns?: boolean;
  initialAdditionalFilter?: string;
  initialFilter?: string;
  preset: WorkspacePreset;
  presetSelector: React.ReactNode;
  queryName: QueryNameKey;
  queryOptions?: Record<string, unknown>;
  relative?: boolean;
  refetchRef?: React.MutableRefObject<(() => void) | undefined>;
  /** fields that should always be requested */
  requiredFields?: string[];
  showCheckboxes?: boolean;
  /**
   * fetch only those fields that are specified in
   * renderSelectiveFields and decoratorSelectiveFields
   * for synthetic columns and columns with decorator
   */
  selectiveFieldsEnabled?: boolean;
  totalComment?: React.ReactNode;
  topFilters?: ITopFilter[];
  showTopFilterApplyButton?: boolean;
  disabledRows?: string[];
  permissionsSettings: PermissionsSettings;
  presetsList?: PresetOptionData[];
  onFiltersUpdate?: (filters: FilterItem) => any;
  onColumnHeader?: ColumnHeaderDecorator;
  onCurrentRowChange?: (item: IDataItem) => any;
  onLoad?: (filters: FilterItem) => any;
  onPresetCreate: (attributes: IPresetAttributes) => any;
  onPresetUpdate: (preset: ExistingWorkspacePreset) => any;
  onPresetDelete: (preset: ExistingWorkspacePreset) => any;
  onRecordDeselect?: (record: IDataItem) => any;
  onRecordSelect?: (record: IDataItem) => any;
  onSelectedRecordsChange?: (records: IDataItem[]) => any;
}

type Props = BaseProps & (PropsWithoutRowExpansion | PropsWithRowExpansion);

const GeneralWorkspace = observer<Props>(
  // eslint-disable-next-line max-lines-per-function
  ({
    sortParams,
    bulkActions,
    columnHeaderHeight,
    columns: columnsSettings = {},
    expandRows,
    exportColumns,
    forceSelectedRecords,
    forceTimezoneOffset,
    header,
    headerDecorator,
    hidePresetFilters,
    hideRowNumbers,
    hideUndefinedColumns = false,
    queryName,
    queryOptions,
    initialAdditionalFilter,
    initialFilter,
    preset,
    presetSelector,
    showCheckboxes,
    relative,
    rowExpansion,
    totalComment,
    topFilters,
    showTopFilterApplyButton,
    refetchRef,
    selectiveFieldsEnabled,
    requiredFields = [],
    disabledRows,
    permissionsSettings,
    presetsList = [],
    onFiltersUpdate,
    onColumnHeader,
    onCurrentRowChange,
    onLoad,
    onPresetCreate,
    onPresetUpdate,
    onPresetDelete,
    onRecordDeselect,
    onRecordSelect,
    onSelectedRecordsChange,
  }) => {
    columnsSettings = columnsWithMoney(columnsSettings);

    const tableRef = useRef<HTMLDivElement>(null);

    const [scalarFields, setScalarFields] = useState<WorkspaceGraphQLScalarField[]>([]);
    const [workspaceObjectFields, setWorkspaceObjectFields] = useState<WorkspaceGraphQLObjectField[]>([]);
    const [currentSortParams, setCurrentSortParams] = useState<ISortParam[]>(sortParams || [defaultSort]);

    const [paginationParams, setPaginationParams] = useState<IPaginationParam>({
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });

    const filters = useFilters();
    const [columns, setColumns] = useState<Column[]>([]);
    const [presetId, setPresetId] = useState<number | undefined>(preset.id);

    const [selectedRecords, setSelectedRecords] = useState<IDataItem[]>([]);
    const currentSelectedRecords = forceSelectedRecords ?? selectedRecords;

    // 1. Request all available attribute names and types for current model via introspection
    const { data: attributesData, loading: attributesLoading } = useQuery<IAttributesResponse>(
      attributesQuery(queryName),
    );

    // Update current sort params if preset has changed.
    useEffect(() => {
      if (preset.sortColumn && preset.sortDirection) {
        setCurrentSortParams([
          {
            sortBy: preset.sortColumn,
            direction: preset.sortDirection,
          },
        ]);
      }
    }, [preset.sortColumn, preset.sortDirection]);

    const enabledColumns = filter(columns, (col) => columnVisible(col.settings?.visibility) && col.enabled);

    // Available sort keys from column keys and sortBy.
    const availableSortKeys = enabledColumns.reduce<string[]>((acc, col) => {
      if (col.settings?.sortBy != null) {
        acc.push(decamelize(col.settings.sortBy));
      } else if (col.settings?.decamelizedName != null) {
        acc.push(col.settings.decamelizedName);
      } else {
        acc.push(decamelize(col.key));
      }

      return acc;
    }, []);

    const querySortParams = currentSortParams.reduce<ISortParam[]>((acc, param) => {
      // Sort only by enabled columns.
      // The backend does not support sorting by fields that are not included in the query.
      const sortKey = availableSortKeys.find((key) => key === param.sortBy);

      if (sortKey != null) {
        acc.push({ sortBy: sortKey, direction: param.direction });
      }

      return acc;
    }, []);

    if (querySortParams.length === 0) {
      querySortParams.push(defaultSort);
    }

    const sortFields = querySortParams.map((sortParam) => camelize(sortParam.sortBy));

    const timezoneOffset = forceTimezoneOffset ?? browserTimezoneOffset;
    const queryFields = filterSelectiveFields({
      columnsSettings,
      enabledColumns,
      requiredFields,
      scalarFields,
      selectiveFieldsEnabled,
      workspaceObjectFields,
      sortFields,
    });
    const query = objectQuery(queryName, queryFields);

    const dataVariables = {
      timezoneOffset: timezoneOffset,
      filterQuery: filters.toQuery,
      queryOptions,
      sortParams: querySortParams,
      paginationParams,
      presetId,
    };

    // 2. Request objects of current type with attributes received from previous query
    const {
      authorized,
      serverMessage,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data,
      loading: dataLoading,
      refetch: refetchData,
      error,
    } = useAuthorizedQuery<ObjectQueryResponse>(query, {
      skip: !scalarFields.length || !enabledColumns.length,
      fetchPolicy: queryName === QueryName.F ? 'no-cache' : 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: dataVariables,
      onCompleted: () => {
        tableRef?.current?.scrollTo(0, 0);
        if (onLoad != null) onLoad(filters);
      },
      onError: () => {
        Notification.Error('Failed to fetch records with provided filters.');
      },
    });

    const handleColumnSort = (sortBy: string) => {
      const existingIndex = findIndex(currentSortParams, (x) => x.sortBy === sortBy);
      const existing = existingIndex !== -1 ? currentSortParams[existingIndex] : undefined;

      setPaginationParams({ ...paginationParams, page: 1 });

      switch (existing?.direction) {
        case 'asc':
          currentSortParams.splice(existingIndex, 1);
          setCurrentSortParams([{ sortBy: existing.sortBy, direction: SortDirectionEnum.Desc }, ...currentSortParams]);
          break;
        case 'desc':
          currentSortParams.splice(existingIndex, 1);
          setCurrentSortParams([...currentSortParams]);
          break;
        default:
          setCurrentSortParams([{ sortBy, direction: SortDirectionEnum.Asc }, ...currentSortParams]);
      }
    };

    const handleSelectedRecordsChange = (newRecords: IDataItem[]) => {
      setSelectedRecords(newRecords);

      if (onSelectedRecordsChange != null) {
        onSelectedRecordsChange(newRecords);
      }
    };

    const handlePageChange = (page: number, pageSize: number = DEFAULT_PAGE_SIZE) => {
      setPaginationParams({ page, pageSize });
    };

    const handleRefresh = () => {
      const newPaginationParams = {
        page: 1,
        pageSize: paginationParams.pageSize,
      };
      setPaginationParams(newPaginationParams);
      setSelectedRecords([]);
      if (onSelectedRecordsChange) {
        onSelectedRecordsChange([]);
      }

      void refetchData({ ...dataVariables, paginationParams: newPaginationParams });
    };

    if (refetchRef) {
      refetchRef.current = handleRefresh;
    }

    const handleRowSelect = (record: IDataItem) => {
      handleSelectedRecordsChange([record, ...currentSelectedRecords]);
      onRecordSelect?.(record);
    };

    const handleRowDeselect = (record: IDataItem) => {
      handleSelectedRecordsChange(filter(currentSelectedRecords, (r) => r.key !== record.key));
      onRecordDeselect?.(record);
    };

    useEffect(() => {
      let {
        __type: { fields },
      } = attributesData || { __type: {} };

      fields = filter(fields, (x) => !includes(x.description, 'skip'));

      const workspaceFields: WorkspaceGraphQLField[] = map(fields, convertField);

      const scalarFields = filter(
        workspaceFields,
        (x) => x.type.kind === 'SCALAR' || x.type.kind === 'ENUM' || includes(x.description, '_allow_array'),
      ) as WorkspaceGraphQLScalarField[];
      setScalarFields(scalarFields);

      let workspaceObjects = filter(
        workspaceFields,
        (x) => x.type.kind === 'OBJECT' && includes(x.description, 'workspace_object'),
      ) as unknown as WorkspaceGraphQLObjectField[];

      workspaceObjects = map(workspaceObjects, (object) => ({
        ...object,
        type: {
          ...object.type,
          fields: map(object.type.fields, convertField),
        },
      }));

      setWorkspaceObjectFields(workspaceObjects);
    }, [attributesData]);

    useEffect(() => {
      if (attributesLoading) return;

      const presetColumns = preset.columns ?? [];

      // 1. Build columns for all scalar fields
      let newColumns: Column[] = map(scalarFields, (field) => {
        const settings = columnsSettings[field.name];

        return {
          field,
          settings,
          enabled: isEmpty(presetColumns) || presetColumns.includes(field.name),
          key: field.name,
          title: settings?.title ?? humanizeString(field.name),
          type: field.type.name ?? GraphQLScalarType.String,
        };
      });

      if (hideUndefinedColumns) {
        newColumns = newColumns.filter(({ settings }) => settings != null);
      }

      // 2. Build defined synthetic columns
      const syntheticColumns: SyntheticColumn[] = map(
        pickBy(columnsSettings, { synthetic: true }) as Record<string, SyntheticColumnSettings<any>>,
        (settings, key) => ({
          key,
          settings,
          synthetic: true,
          enabled: isEmpty(presetColumns) || presetColumns.includes(key),
          title: settings.title,
          type: GraphQLScalarType.String,
        }),
      );

      newColumns = newColumns.concat(syntheticColumns);

      // 3. Build columns for each attribute of each object field
      const objectColumns: Column[] = flatMap(filter(workspaceObjectFields, { list: false }), (field) =>
        map(field.type.fields, (subfield) => {
          const name = `${field.name}__${subfield.name}`;
          const settings = columnsSettings[name];

          return {
            field,
            subfield,
            settings,
            enabled: isEmpty(presetColumns) || presetColumns.includes(name),
            key: name,
            title: settings?.title ?? `${humanizeString(field.name)}: ${humanizeString(subfield.name)}`,
            type: subfield.type.name ?? GraphQLScalarType.String,
          };
        }),
      );

      newColumns = newColumns.concat(objectColumns);

      const settingsKeys = Object.keys(columnsSettings);

      newColumns = sortBy(newColumns, (c) => {
        if (presetColumns.indexOf(c.key) > -1) {
          return presetColumns.indexOf(c.key);
        }

        if (settingsKeys.indexOf(c.key) > -1) {
          return presetColumns.length + settingsKeys.indexOf(c.key);
        }

        return presetColumns.length + settingsKeys.length;
      });

      setColumns(newColumns);
      setPresetId(preset.id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preset.columns, scalarFields]);

    useEffect(() => {
      const newFilter = FilterItem.fromQuery(preset.query ?? '') ?? new FilterItem({ root: true });
      filters.assignRoot(newFilter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preset.query]);

    useEffect(() => {
      if (hidePresetFilters !== true) return;

      if (initialAdditionalFilter == null) return;

      const combinedFilter = filters.combine(FilterItem.fromQuery(initialAdditionalFilter));
      filters.assignRoot(combinedFilter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialAdditionalFilter, preset.id]);

    useEffect(() => {
      if (initialFilter == null) return;

      if (preset.id != null) return;

      const newFilter = FilterItem.fromQuery(initialFilter) ?? new FilterItem({ root: true });
      filters.assignRoot(newFilter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const query = new URLSearchParams(window.location.search);

      if (query.get('filter')) {
        const newFilter = FilterItem.fromQuery(decodeURI(query.get('filter') || '')) ?? new FilterItem({ root: true });

        // Combine filter from query string with a preset
        // preset = "state = 'draft'"
        // ?preset=2&filter=id=10 => state = 'draft' AND id = '10'
        filters.assignRoot(filters.combine(newFilter));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (attributesLoading || dataLoading) {
      return <PageLoader title="Loading data..." isVisible />;
    }

    if (!authorized) {
      return <NotAuthorized message={serverMessage} />;
    }

    const dataSource = map((data ?? {})[queryName]?.objects, (item) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      key: item.id.toString(),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data: item,
    }));

    const total = (data ?? {})[queryName]?.total ?? 0;

    return (
      <PageContainer relative={relative}>
        <MainContainer relative={relative}>
          <PageHeader>
            <FiltersAndActions
              columns={columns}
              header={header}
              headerDecorator={headerDecorator}
              hidePresetFilters={hidePresetFilters}
              hideUndefinedColumns={hideUndefinedColumns}
              preset={preset}
              presetSelector={presetSelector}
              queryName={queryName}
              permissionsSettings={permissionsSettings}
              selectedRecords={currentSelectedRecords}
              scalarFields={scalarFields}
              sortParams={currentSortParams}
              topFilters={topFilters}
              showTopFilterApplyButton={showTopFilterApplyButton}
              workspaceObjectFields={workspaceObjectFields}
              presetsList={presetsList}
              onColumnsUpdate={setColumns}
              onSortParamsUpdate={setCurrentSortParams}
              onFiltersUpdate={onFiltersUpdate}
              onPresetCreate={onPresetCreate}
              onPresetUpdate={onPresetUpdate}
              onPresetDelete={onPresetDelete}
            />
          </PageHeader>
          <PageContent>
            {error ? (
              <ErrorMessage>
                Failed to fetch records with provided filters. <br />
                Try a different filter or reload the page.
              </ErrorMessage>
            ) : (
              <TableWrapper>
                <TableTop
                  paginationParams={paginationParams}
                  total={total}
                  totalComment={totalComment}
                  onPageChange={handlePageChange}
                  onRefresh={handleRefresh}
                  columns={columns}
                  exportColumns={exportColumns}
                  header={header}
                  queryName={queryName}
                  preset={preset}
                  queryOptions={queryOptions}
                  selectedRecords={currentSelectedRecords}
                  sortParams={currentSortParams}
                  timezoneOffset={timezoneOffset}
                  onPresetUpdate={onPresetUpdate}
                  bulkActions={bulkActions}
                  permissionsSettings={permissionsSettings}
                />

                {bulkActions == null && !showCheckboxes ? (
                  <RecordsTable
                    columnHeaderHeight={columnHeaderHeight}
                    columns={enabledColumns}
                    dataSource={dataSource}
                    expandRows={expandRows}
                    hideRowNumbers={hideRowNumbers}
                    rowExpansion={rowExpansion}
                    sortedColumns={currentSortParams}
                    tableRef={tableRef}
                    onColumnHeader={onColumnHeader}
                    onColumnSort={handleColumnSort}
                    onCurrentRowChange={onCurrentRowChange}
                  />
                ) : (
                  <RecordsTable
                    columnHeaderHeight={columnHeaderHeight}
                    columns={enabledColumns}
                    dataSource={dataSource}
                    expandRows={expandRows}
                    hideRowNumbers={hideRowNumbers}
                    relative={relative}
                    rowExpansion={rowExpansion}
                    sortedColumns={currentSortParams}
                    showCheckboxes
                    selectedRows={currentSelectedRecords}
                    onColumnSort={handleColumnSort}
                    onCurrentRowChange={onCurrentRowChange}
                    onColumnHeader={onColumnHeader}
                    onRowSelect={handleRowSelect}
                    onRowDeselect={handleRowDeselect}
                    onRowSelectAll={(records) => handleSelectedRecordsChange(records)}
                    onRowDeselectAll={() => handleSelectedRecordsChange([])}
                    tableRef={tableRef}
                    disabledRows={disabledRows}
                  />
                )}
              </TableWrapper>
            )}
          </PageContent>
        </MainContainer>
      </PageContainer>
    );
  },
);

export default withPresets(withPermissions(withPageTitle(GeneralWorkspace)));
