// TODO: Too many files need to be changed to fix those linter errors.
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { EditEnquiryVatFormValues } from 'components/Deal/Enquiries/shared/Summary/VatEditModal';
import Notification from 'components/UI/Notification';
import {
  Certificates,
  Enquiry,
  EnquiryFeatures,
  EnquiryMaterial,
  EnquiryPart,
  EnquiryPartFile,
  EnquiryPromotion,
  EnquirySimilarPart,
} from 'interfaces';
import { EnquiryPartPriceOptionType } from 'interfaces/graphql';
import { ICurrentlyViewing } from 'interfaces/shared';
// eslint-disable-next-line no-restricted-imports
import { each, find, get as _get, join, sortBy } from 'lodash-es';
import { action, get, observable } from 'mobx';
import qs from 'qs';
import { ReactText } from 'react';
import { routes } from 'routes';
import { routesApi } from 'routes/api';
import { RootStore } from 'stores/RootStore';
import { ModalStore } from 'stores/shared/ModalStore';
import api, { camelizeKeys, decamelizeKeys, jsonToFormData, requestBuilder } from 'utils/axios';
import downloader from 'utils/hooks/useDownload';

import { CloneQuoteResponse } from './EnquiriesStore.types';

interface IConfirmDraftProps {
  sendStateEmail: boolean;
}

class EnquiriesStore {
  rootStore: RootStore;

  @observable isEnquiryFetching = false;
  @observable isUpdateInProcess = false;
  @observable isPromotionFetching = false;
  @observable isPromotionDestroy = false;
  @observable isFeaturesFetching = false;
  @observable isPartFetching = false;
  @observable priceOptionFetchingPartIdList = [] as number[];
  @observable isCASessionFetching = false;
  @observable isSimilarFetching = false;
  @observable isFileUpload = false;
  @observable isSwapInProcess = false;
  @observable isReissueInProcess = false;
  @observable isFetchingFile = false;
  @observable forceResetLoading = false;
  @observable enquiry: Enquiry = {} as Enquiry;
  @observable selected: number[] = [];
  @observable collapsed: number[] = [];
  @observable promotion: EnquiryPromotion = {} as EnquiryPromotion;
  @observable similarParts: EnquirySimilarPart[] = [];
  @observable currentlyViewing: EnquirySimilarPart[] = [];

  @observable features: EnquiryFeatures = {
    processes: [],
    materialTypes: [],
    finishes: [],
  };

  @observable materials: EnquiryMaterial[] = [];

  @observable drawingsModal: {
    allFiles: unknown[];
    updates: Array<{ id: string | number; _destroy: boolean; file: { name: string } }>;
  } = {
    allFiles: [],
    updates: [],
  };

  @observable isDownloadingPDF = false;

  mailerModal = new ModalStore();
  downloadPdfModal = new ModalStore();
  cloneModal = new ModalStore();
  cancelModal = new ModalStore();
  reissueModal = new ModalStore();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action setIsDownloadingPDF = (value: boolean) => {
    this.isDownloadingPDF = value;
  };

  @action downloadPdf = (partIds: number[] = [], addQtyOptions?: boolean) => {
    this.setIsDownloadingPDF(true);

    const download = downloader();

    download(
      routesApi.enquiryPdfPath(this.enquiry.id, partIds, addQtyOptions ? 'quantity_options' : undefined),
      `Xometry-Quote-${this.enquiry.number}.pdf`,
    )
      .then(() => {
        this.downloadPdfModal.closeModal();
      })
      .catch(() => {
        Notification.Error('Failed to download PDF');
      })
      .finally(() => {
        this.setIsDownloadingPDF(false);
      });
  };

  @action downloadPdfClick = () => {
    const activeParts = this.enquiry.parts.filter((part) => !part.deleted);

    if (activeParts.length) {
      this.downloadPdfModal.openModal();
    } else {
      void this.downloadPdf();
    }
  };

  @action setFileUpload = (value: boolean) => {
    this.isFileUpload = value;
  };

  @action setPartPriceOptionFetching = (partId: number, loadingState: boolean) => {
    this.priceOptionFetchingPartIdList = loadingState
      ? [...this.priceOptionFetchingPartIdList, partId]
      : this.priceOptionFetchingPartIdList.filter((id) => id !== partId);
  };

  @action setPartFetching = (value: boolean) => {
    this.isPartFetching = value;
  };

  @action setFeaturesFetching = (value: boolean) => {
    this.isFeaturesFetching = value;
  };

  @action setEnquiry = (enquiry: Enquiry) => {
    this.enquiry = enquiry;
  };

  @action setIsPromotionFetching = (value: boolean) => {
    this.isPromotionFetching = value;
  };

  @action setIsPromotionDestroy = (value: boolean) => {
    this.isPromotionDestroy = value;
  };

  @action setPromotion = (promotion: EnquiryPromotion) => {
    this.promotion = promotion;
  };

  @action setFetching = (value: boolean) => {
    this.isEnquiryFetching = value;
  };

  @action setUpdateInProcess = (value: boolean) => {
    this.isUpdateInProcess = value;
  };

  @action setFeatures = (features: EnquiryFeatures) => {
    this.features = {
      ...this.features,
      ...features,
    };
  };

  @action setMaterials = (materials: EnquiryMaterial[]) => {
    this.materials = materials;
  };

  @action setCertificates = (certificates: Certificates) => {
    this.enquiry.certificates = certificates;
  };

  @action setSelectedPartIds = (ids: number[]) => {
    this.selected = ids;
  };

  @action setSessionFetching = (value: boolean) => {
    this.isCASessionFetching = value;
  };

  @action setSimilarFetching = (value: boolean) => {
    this.isSimilarFetching = value;
  };

  @action setSwapInProcess = (value: boolean) => {
    this.isSwapInProcess = value;
  };

  @action setReissueInProcess = (value: boolean) => {
    this.isReissueInProcess = value;
  };

  @action setDrawingsModalUpdates = (updates: any[]) => {
    this.drawingsModal.updates = [...updates];
  };

  @action setForceResetLoading = (value: boolean) => {
    this.forceResetLoading = value;
  };

  @action resetDrawingUpdatesFor = (afid: number) => {
    this.setDrawingsModalUpdates(this.drawingsModal.updates.filter((data: any) => data.id !== afid));
  };

  @action resetDrawingUpdatesForParent = (afid: number) => {
    this.setDrawingsModalUpdates(this.drawingsModal.updates.filter((data: any) => data.parent_id !== afid));
  };

  @action mergeEnquiryParts = (enquiryParts: EnquiryPart[]) => {
    const partIds = this.enquiry.parts.map((p) => p.id);
    const newEnquiryParts = enquiryParts.filter((ep) => !partIds.includes(ep.id));
    const parts = [
      ...this.enquiry.parts.map((part: EnquiryPart) => {
        const newPart = enquiryParts.find((p: EnquiryPart) => p.id === part.id);

        if (newPart) return newPart;

        return part;
      }),
      ...newEnquiryParts,
    ];

    this.enquiry = {
      ...this.enquiry,
      parts: sortBy(parts, (p) => p.deleted),
    };
  };

  @action list = async (personId: number): Promise<any> => {
    try {
      const params = qs.stringify(
        {
          filters: {
            person_id: personId,
            state: ['pending', 'draft'],
          },
        },
        { arrayFormat: 'brackets' },
      );
      const url = `${routesApi.enquiriesPath()}?${params}`;
      const response = await api.get(url);

      return response.data.payload;
    } catch (e: unknown) {
      console.error(e);

      return false;
    }
  };

  @action fetchEnquiryForPage = async (
    dealId: string | number,
    enquiryId: string | number,
    withPreloader = true,
  ): Promise<any> => {
    this.setFetching(withPreloader);

    try {
      const response = await api.get(routesApi.dealEnquiryPath(dealId, enquiryId));
      this.updateEnquiry(response.data.payload);
    } catch (e: unknown) {
      console.error(e);
      Notification.Error("Can't find requested enquiry");
    } finally {
      this.setFetching(false);
    }
  };

  @action updateEnquiryCurrency = async (enquiryId: string | number, currency: string): Promise<any> => {
    this.setFetching(true);

    try {
      const response = await api.patch(routesApi.enquiryUpdateCurrency(enquiryId), { currency });
      this.updateEnquiry(response.data.payload);
    } catch (e: unknown) {
      console.error(e);
      Notification.Error('Error updating currency');
    } finally {
      this.setFetching(false);
    }
  };

  @action fetchEnquiry = async (enquiryId: string, withPreloader = true): Promise<any> => {
    this.setFetching(withPreloader);

    try {
      const response = await api.get(routesApi.enquiryPath(enquiryId));
      this.updateEnquiry(response.data.payload);
    } catch (e: unknown) {
      console.error(e);
      Notification.Error("Can't find requested enquiry");
    } finally {
      this.setFetching(false);
    }
  };

  @action fetchSimilarParts = async (enquiryId: number, partId: number): Promise<any> => {
    this.setSimilarFetching(true);
    try {
      const response = await api.get(`${routesApi.enquiryPartsPath(enquiryId, partId)}/similar_parts`);
      this.setSimilarParts(response.data.payload);
    } catch (e: unknown) {
      console.error(e);
      Notification.Error("Can't load similar parts.");
    } finally {
      this.setSimilarFetching(false);
    }
  };

  @action create = async (dealId: ReactText): Promise<AxiosResponse | null> => {
    this.rootStore.pageLoaderStore.enable();

    try {
      const response = await api.post(routesApi.enquiriesPath(), {
        deal_id: dealId,
      });
      const enquiry = camelizeKeys(_get(response, 'data.payload'));

      this.rootStore.history.push(routes.enquiryEditPath(String(dealId), enquiry.id));

      return response;
    } catch (e: unknown) {
      console.error(e);

      return null;
    } finally {
      this.rootStore.pageLoaderStore.disable();
    }
  };

  @action update = async (enquiryId: string | number, params: any = {}): Promise<any> => {
    this.setUpdateInProcess(true);

    try {
      const response = await api.patch(routesApi.enquiryPath(enquiryId), {
        enquiry: decamelizeKeys(params),
      });

      this.updateEnquiry(response.data.payload);

      this.rootStore.activityRecordStore.refetch();
    } catch (e: unknown) {
      console.error(e);
      Notification.Error("Can't update requested enquiry");
    } finally {
      this.setUpdateInProcess(false);
    }
  };

  @action updateState = async (enquiryId: string | number, params: any = {}): Promise<any> => {
    this.setFetching(true);

    try {
      const response = await api.patch(routesApi.enquiryStatePath(enquiryId), {
        enquiry: decamelizeKeys(params),
      });
      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();
      void this.rootStore.dealSidebarStore.refetch();
      Notification.Success('Quote state successfully updated.');
    } catch (e: unknown) {
      console.error(e);
      this.showErrors(e);
    } finally {
      this.setFetching(false);
    }
  };

  @action toggleCancel = async (enquiryId: string | number, params: any = {}): Promise<any> => {
    this.setFetching(true);

    try {
      const response = await api.patch(routesApi.enquiryCancelPath(enquiryId), {
        enquiry: decamelizeKeys(params),
      });
      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();
      void this.rootStore.dealSidebarStore.refetch();
    } catch (e: unknown) {
      console.error(e);
      this.showErrors(e);
    } finally {
      this.setFetching(false);
    }
  };

  @action recalculateSummary = async (enquiryId: string | number): Promise<void> => {
    this.setUpdateInProcess(true);

    try {
      const response = await api.post(routesApi.enquiryRecalculateSummaryPath(enquiryId));
      this.updateEnquiry(response.data.payload);
    } catch (e: unknown) {
      console.error(e);
      Notification.Error('Failed to recalculate enquiry attributes');
    } finally {
      this.setUpdateInProcess(false);
    }
  };

  @action updatePartFeatures = async (partId: number, params: any = {}): Promise<any> => {
    try {
      const response = await api.patch(
        `${routesApi.enquiryPartsPath(this.enquiry.id, partId)}/update_features`,
        decamelizeKeys(params),
      );
      const enquiry = camelizeKeys(_get(response, 'data.payload'));
      this.updateEnquiry(enquiry);
      this.rootStore.activityRecordStore.refetch();

      return response;
    } catch (e) {
      this.showErrors(e);

      return false;
    }
  };

  @action createAdditionalService = async (enquiryId: number, params: any = {}): Promise<any> => {
    try {
      const response = await api.post(routesApi.enquiryCreateAdditionalServicePath(enquiryId), decamelizeKeys(params));
      const enquiry = camelizeKeys(_get(response, 'data.payload'));
      this.updateEnquiry(enquiry);
      this.rootStore.activityRecordStore.refetch();

      return response;
    } catch (e) {
      this.showErrors(e);

      return false;
    }
  };

  @action updateAdditionalService = async (enquiryId: number, params: any = {}, serviceId: number): Promise<any> => {
    try {
      const response = await api.patch(
        routesApi.enquiryUpdateAdditionalServicePath(enquiryId, serviceId),
        decamelizeKeys(params),
      );
      const enquiry = camelizeKeys(_get(response, 'data.payload'));
      this.updateEnquiry(enquiry);
      this.rootStore.activityRecordStore.refetch();

      return response;
    } catch (e) {
      this.showErrors(e);

      return false;
    }
  };

  @action updateVAT = async (enquiryId: number, params: EditEnquiryVatFormValues): Promise<any> => {
    try {
      const data = params.method === 'auto' ? {} : { vatRate: params.vat };

      const response = await api.patch(routesApi.enquiryUpdateVatPath(enquiryId), decamelizeKeys(data));
      const enquiry = camelizeKeys(_get(response, 'data.payload'));
      this.updateEnquiry(enquiry);
      this.rootStore.activityRecordStore.refetch();

      return response;
    } catch (e) {
      this.showErrors(e);

      return false;
    }
  };

  @action deleteAdditionalService = async (enquiryId: number, serviceId: number): Promise<any> => {
    try {
      const response = await api.delete(routesApi.enquiryUpdateAdditionalServicePath(enquiryId, serviceId));
      const enquiry = camelizeKeys(_get(response, 'data.payload'));
      this.updateEnquiry(enquiry);
      this.rootStore.activityRecordStore.refetch();

      return response;
    } catch (e) {
      this.showErrors(e);

      return false;
    }
  };

  @action cloneQuote = async (enquiryId: string | number, params: any = {}): Promise<CloneQuoteResponse | false> => {
    try {
      const response = await api.post(routesApi.enquiryCloneQuote(enquiryId), decamelizeKeys(params));
      const { payload } = response.data;
      this.rootStore.activityRecordStore.refetch();

      return payload;
    } catch (e) {
      this.showErrors(e);

      return false;
    }
  };

  @action bulkCloneParts = async (enquiryId: string | number, params: any = {}): Promise<any> => {
    try {
      const response = await api.post(routesApi.enquiryBulkCloneParts(enquiryId), decamelizeKeys(params));
      const { payload } = response.data;

      if (params.destination === 'same') this.updateEnquiry(payload);

      this.rootStore.activityRecordStore.refetch();

      return payload;
    } catch (e) {
      this.showErrors(e);

      return false;
    }
  };

  @action bulkUpdateStandard = async (enquiryId: string | number, params: any = {}): Promise<any> => {
    try {
      const response = await api.patch(routesApi.enquiryBulkUpdateStandard(enquiryId), decamelizeKeys(params));
      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();

      return true;
    } catch (e) {
      this.showErrors(e);

      return false;
    }
  };

  @action bulkUpdateExpress = async (enquiryId: string | number, params: any = {}): Promise<any> => {
    try {
      const response = await api.patch(routesApi.enquiryBulkUpdateExpress(enquiryId), decamelizeKeys(params));
      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();

      return true;
    } catch (e) {
      this.showErrors(e);

      return false;
    }
  };

  @action bulkUpdateNotes = (
    parts: {
      id: string;
      notes?: string | null;
    }[],
  ) => {
    each(parts, (part) => {
      const enqPart = find(this.enquiry.parts, { id: part.id }) as EnquiryPart;

      if (enqPart) {
        enqPart.notes = part.notes;
      }
    });
  };

  @action bulkUpdateFeatures = async (enquiryId: string | number, params: any = {}): Promise<boolean> => {
    try {
      const response = await api.patch(routesApi.enquiryBulkUpdateFeatures(enquiryId), decamelizeKeys(params));
      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();

      return true;
    } catch (e) {
      this.showErrors(e);

      return false;
    }
  };

  @action bulkUpdateRfq = async (enquiryId: string | number, params: any = {}): Promise<any> => {
    try {
      const response = await api.patch(routesApi.enquiryBulkUpdateRfq(enquiryId), decamelizeKeys(params));
      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();

      return true;
    } catch (e) {
      this.showErrors(e);

      return false;
    }
  };

  @action bulkUpdatePreQuote = async (enquiryId: string | number, fd: FormData): Promise<any> => {
    try {
      const response = await api.patch(routesApi.enquiryBulkUpdatePreQuote(enquiryId), fd);
      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();

      return true;
    } catch (e) {
      this.showErrors(e);

      return false;
    }
  };

  @action bulkUpdateTags = async (enquiryId: string | number, fd: FormData): Promise<boolean> => {
    try {
      const response = await api.patch(routesApi.enquiryBulkUpdateTags(enquiryId), fd);
      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();

      return true;
    } catch (e) {
      this.showErrors(e);

      return false;
    }
  };

  @action bulkUpdateManualPriceExpiration = async () => {
    this.rootStore.pageLoaderStore.enable();

    try {
      const partIds = this.selectedParts.map((part) => part.id);
      const response = await api.post(
        routesApi.enquiryBulkUpdateManualPriceExpiration(this.enquiry.id),
        decamelizeKeys({ partIds }),
      );
      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();

      return true;
    } catch (e) {
      this.showErrors(e);

      return false;
    } finally {
      this.rootStore.pageLoaderStore.disable();
    }
  };

  @action bulkUpdateIQEPrice = async () => {
    this.rootStore.pageLoaderStore.enable();

    try {
      const partIds = this.selectedParts.map((part) => part.id);
      const response = await api.post(
        routesApi.enquiryBulkUpdateIQEPrice(this.enquiry.id),
        decamelizeKeys({ partIds }),
      );
      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();

      return true;
    } catch (e) {
      this.showErrors(e);

      return false;
    } finally {
      this.rootStore.pageLoaderStore.disable();
    }
  };

  @action updatePartManualPriceExpiration = async (partId: string | number) => {
    this.setPartFetching(true);

    try {
      const response = await api.post(routesApi.enquiryPartUpdateManualPriceExpiration(this.enquiry.id, partId));
      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();

      return true;
    } catch (e: unknown) {
      console.error(e);

      return false;
    } finally {
      this.setPartFetching(false);
    }
  };

  @action updatePart = async (part: EnquiryPart, params: any = {}) => {
    try {
      const response = await api.patch(routesApi.enquiryPartsPath(part.enquiryId, part.id), decamelizeKeys(params));
      const { payload } = response.data;
      this.updateEnquiry(payload);

      this.rootStore.activityRecordStore.refetch();

      return true;
    } catch (e) {
      this.showErrors(e);

      return false;
    }
  };

  @action updatePartDrawings = async (part: EnquiryPart, params: any): Promise<unknown> => {
    this.rootStore.pageLoaderStore.enable();
    try {
      const response = await api.patch(
        `${routesApi.enquiryPartsPath(this.enquiry.id, part.id)}/update_drawings`,
        jsonToFormData(params),
      );
      const { payload } = response.data;
      this.updateEnquiry(payload);

      this.rootStore.activityRecordStore.refetch();

      return true;
    } catch (e) {
      this.showErrors(e);

      return false;
    } finally {
      this.rootStore.pageLoaderStore.disable();
    }
  };

  @action updatePartPriceOption = async (partId: number, option: EnquiryPartPriceOptionType): Promise<any> => {
    this.setPartPriceOptionFetching(partId, true);
    try {
      const response = await api.patch(`${routesApi.enquiryPartsPath(this.enquiry.id, partId)}/update_price_option`, {
        option_selected: option,
      });
      this.updateEnquiry(response.data.payload);
      Notification.Success('Part price option has been updated!');

      this.rootStore.activityRecordStore.refetch();
    } catch (e: unknown) {
      console.error(e);

      Notification.Error(`Can't update price option for part ID: ${partId}`);
    } finally {
      this.setPartPriceOptionFetching(partId, false);
    }
  };

  @action updatePartQuantityOption = async (partId: number, quantity: number): Promise<any> => {
    try {
      const response = await api.patch(
        `${routesApi.enquiryPartsPath(this.enquiry.id, partId)}/update_quantity_option`,
        { quantity },
      );
      const { payload } = response.data;
      this.updateEnquiry(payload);

      this.rootStore.activityRecordStore.refetch();

      return true;
    } catch (e: unknown) {
      console.error(e);

      return false;
    }
  };

  @action updatePartQuantityOptions = async (partId: number, params: any): Promise<any> => {
    try {
      const response = await api.patch(
        `${routesApi.enquiryPartsPath(this.enquiry.id, partId)}/update_quantity_options`,
        decamelizeKeys(params),
      );
      const { payload } = response.data;
      this.updateEnquiry(payload);

      this.rootStore.activityRecordStore.refetch();

      return true;
    } catch (e: unknown) {
      console.error(e);

      return false;
    }
  };

  @action copyPartPreQuote = async (partId: number, fromPartId: number): Promise<any> => {
    try {
      const response = await api.patch(`${routesApi.enquiryPartsPath(this.enquiry.id, partId)}/copy_pre_quote`, {
        from_id: fromPartId,
      });
      const { payload } = response.data;
      this.updateEnquiry(payload);

      this.rootStore.activityRecordStore.refetch();

      return true;
    } catch (e: unknown) {
      console.error(e);

      return false;
    }
  };

  @action iqeOptions = async (partId: number, quantities: number[]): Promise<any> => {
    try {
      const response = await api.get(
        `${routesApi.enquiryPartsPath(this.enquiry.id, partId)}/iqe_options?quantities=${quantities.join(',')}`,
      );
      const payload = _get(response, 'data.payload');

      return camelizeKeys(payload);
    } catch (e: unknown) {
      console.error(e);

      return false;
    }
  };

  @action updateEnquiry = (enquiry: Enquiry) => {
    this.enquiry = {
      ...camelizeKeys(enquiry),
      certificates: enquiry.certificates,
    };
  };

  @action renewPart = (partId: number, payload: any) => {
    const partIndex = this.enquiry.parts.findIndex((p) => p.id === partId);
    this.enquiry.parts[partIndex] = camelizeKeys(payload);
  };

  @action fetchPromotion = async (enquiryId: number, code: string): Promise<AxiosResponse> => {
    this.setIsPromotionFetching(true);

    try {
      const response = await api.get(`${routesApi.enquiryCheckPromotionPath(enquiryId)}?code=${code}`);
      const payload = _get(response, 'data.payload');
      this.setPromotion(camelizeKeys(payload));

      return response;
    } catch (e: any) {
      const status = _get(e, 'response.status');

      if (status === 404) {
        Notification.Error(`Coupon ${code} doesn't exist!`);
        this.setPromotion({} as EnquiryPromotion);
      }

      return e.response as AxiosResponse;
    } finally {
      this.setIsPromotionFetching(false);
    }
  };

  @action updatePromotionSettings = async (params: any) => {
    this.setUpdateInProcess(true);

    try {
      const response = await api.post(routesApi.enquiryUpdatePropmotions(this.enquiry.id), { promotion: params });
      const payload = _get(response, 'data.payload');

      this.setEnquiry(camelizeKeys(payload));
      this.setPromotion({} as EnquiryPromotion);
      this.rootStore.activityRecordStore.refetch();

      return response;
    } catch (e: any) {
      return e?.response as AxiosResponse;
    } finally {
      this.setUpdateInProcess(false);
    }
  };

  @action fetchFeatures = async (): Promise<AxiosResponse> => {
    this.setFeaturesFetching(true);

    try {
      const response = await api.get(routesApi.enquiryFeaturesPath());
      const payload = _get(response, 'data.payload');

      this.setFeatures(camelizeKeys(payload));

      return response;
    } catch (e: any) {
      console.error(e);

      return e.response as AxiosResponse;
    } finally {
      this.setFeaturesFetching(false);
    }
  };

  @action fetchMaterials = async (materialTypeId: number): Promise<AxiosResponse> => {
    this.setFeaturesFetching(true);

    try {
      const response = await api.get(routesApi.enquiryMaterialsPath(materialTypeId));
      const payload = _get(response, 'data.payload');

      this.setMaterials(camelizeKeys(payload));

      return response;
    } catch (e: any) {
      return e?.response as AxiosResponse;
    } finally {
      this.setFeaturesFetching(false);
    }
  };

  @action createParts = async (payload: any, callback: () => void): Promise<AxiosResponse> => {
    this.setPartFetching(true);

    try {
      const response = await api.post(`${routesApi.enquiryPath(this.enquiry.id)}/parts`, {
        parts: decamelizeKeys(payload),
      });
      const parts = _get(response, 'data.payload.parts');

      this.mergeEnquiryParts(camelizeKeys(parts));
      callback();

      return response;
    } catch (e: any) {
      const msg = _get(e, 'response.data.errors.source');

      if (msg) Notification.Error(msg);

      return e as AxiosResponse;
    } finally {
      this.setPartFetching(false);
      this.rootStore.activityRecordStore.refetch();
    }
  };

  @action cancelParts = async () => {
    this.setFetching(true);

    try {
      const response = await api.post(`${routesApi.enquiryPath(this.enquiry.id)}/parts/cancel_parts`, {
        part_ids: this.selected,
      });
      const payload = _get(response, 'data.payload');
      this.setEnquiry(camelizeKeys(payload));
      this.setSelectedPartIds([]);

      return response;
    } catch (e) {
      return e;
    } finally {
      this.setFetching(false);
      this.rootStore.activityRecordStore.refetch();
    }
  };

  @action restoreParts = async () => {
    this.setFetching(true);

    try {
      const response = await api.post(`${routesApi.enquiryPath(this.enquiry.id)}/parts/restore_parts`, {
        part_ids: this.selected,
      });
      const payload = _get(response, 'data.payload');
      this.setEnquiry(camelizeKeys(payload));

      return response;
    } catch (e) {
      return e;
    } finally {
      this.setFetching(false);
      this.rootStore.activityRecordStore.refetch();
    }
  };

  @action restorePart = async (part: EnquiryPart) => {
    try {
      const response = await api.patch(routesApi.enquiryRestorePartPath(this.enquiry.id, part.id));
      const payload = _get(response, 'data.payload');
      this.updateEnquiry(camelizeKeys(payload));
      this.rootStore.activityRecordStore.refetch();

      return response;
    } catch (e: unknown) {
      console.error(e);

      return false;
    }
  };

  @action selectPart = (id: number): number[] => {
    if (this.selected.includes(id)) {
      this.selected = this.selected.filter((partId: number) => partId !== id);
    } else {
      this.selected = [...this.selected, id];
    }

    return this.selected;
  };

  @action toggleAllParts = () => {
    const { parts } = this.enquiry;
    const activeParts = parts.filter((part) => !part.deleted);

    if (this.selected.length === activeParts.length) {
      this.selected = [];
    } else {
      this.selected = activeParts.map((part: EnquiryPart) => part.id);
    }
  };

  @action collapseParts = (ids: number[]): number[] => {
    this.collapsed = ids;

    return this.collapsed;
  };

  @action emulateCustomerSession = async () => {
    this.setSessionFetching(true);
    try {
      const response = await api.get(`${routesApi.enquiryPath(this.enquiry.id)}/emulate`);
      const link = _get(response, 'data.payload.link');

      if (!link) {
        Notification.Error("Person doesn't have account");

        return false;
      }

      window.open(link, '_blank');

      return true;
    } catch (e) {
      Notification.Error("Can't create person session =(");

      return e;
    } finally {
      this.setSessionFetching(false);
    }
  };

  @action private refreshPartFiles = (partId: number, files: EnquiryPartFile[]) => {
    this.enquiry = {
      ...this.enquiry,
      parts: [
        ...this.enquiry.parts.map((part) => {
          if (part.id === partId) {
            return {
              ...part,
              files,
            };
          }

          return part;
        }),
      ],
    };
  };

  @action uploadAdditionalFile = async (partId: number, file: File, params: any) => {
    this.setFileUpload(true);

    const fd = new FormData();
    fd.append('file', file);
    Object.keys(params).forEach((key) => fd.append(key, params[key]));

    try {
      const response = await api.post(routesApi.enquiryPartsFileUploadPath(this.enquiry.id, partId), fd);
      const files = _get(response, 'data.payload');
      this.refreshPartFiles(partId, camelizeKeys(files));
    } catch (e: unknown) {
      console.error(e);

      Notification.Error('Can not upload file');
    } finally {
      this.setFileUpload(false);
    }
  };

  @action requestModeration = async () => {
    this.setFetching(true);

    try {
      const response = await api.post(routesApi.enquiryRequestModerationPath(this.enquiry.id));

      this.updateEnquiry(response.data.payload);
      Notification.Info('Moderation request sent.');
    } catch (e) {
      console.error(e);
    } finally {
      this.setFetching(false);
    }
  };

  @action completeModeration = async () => {
    this.setFetching(true);

    try {
      const response = await api.post(routesApi.enquiryCompleteModerationPath(this.enquiry.id));

      this.updateEnquiry(response.data.payload);
      Notification.Info('Moderation completed!');
    } catch (e) {
      console.error(e);
    } finally {
      this.setFetching(false);
    }
  };

  @action confirmDraft = async (props: IConfirmDraftProps) => {
    this.setFetching(true);

    try {
      const response = await api.post(routesApi.enquiryConfirmDraft(this.enquiry.id), decamelizeKeys(props));

      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();
      Notification.Info('Quote has been checked!');
    } catch (e: any) {
      let stack = ['Quote has not been checked'];

      if (e?.response) {
        let responseErrors = _get(e, 'response.data.errors.source.errors') || [];

        if (typeof responseErrors === 'object' && !!responseErrors.base) {
          responseErrors = responseErrors.base;
        }

        stack = [...stack, ...responseErrors];
      }

      Notification.Error(join(stack, ', '));
    } finally {
      this.setFetching(false);
    }
  };

  @action finishCalc = async () => {
    this.setFetching(true);

    try {
      const response = await api.post(routesApi.enquiryCalcFinishedPath(this.enquiry.id));

      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();
      Notification.Success('Quote calculation finished');
    } catch (e: unknown) {
      console.error(e);

      Notification.Error('Calculation has not been finished');
    } finally {
      this.setFetching(false);
    }
  };

  @action returnToCalc = async () => {
    this.setFetching(true);

    try {
      const response = await api.post(routesApi.enquiryReturnToCalcPath(this.enquiry.id));

      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();
      Notification.Success('The quote has been returned to calculation');
    } catch (e: unknown) {
      console.error(e);

      Notification.Error("Can't return quote to calculation");
    } finally {
      this.setFetching(false);
    }
  };

  @action skipCalculation = async () => {
    this.setFetching(true);

    try {
      const response = await api.post(routesApi.enquirySkipCalculationPath(this.enquiry.id));

      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();
      Notification.Success('Quote state #calculation has been skipped');
    } catch (e: unknown) {
      console.error(e);

      Notification.Error("Can't skip calculation");
    } finally {
      this.setFetching(false);
    }
  };

  @action swapFile = async (partId: number, fileId: number, recalculate: boolean) => {
    this.setSwapInProcess(true);

    try {
      const response = await api.post(
        routesApi.enquiryPartFileSwapPath(this.enquiry.id, partId),
        decamelizeKeys({ fileId, recalculate }),
      );

      this.updateEnquiry(response.data.payload);
      this.rootStore.activityRecordStore.refetch();
      Notification.Info('Original file was changed');
    } catch (e: unknown) {
      console.error(e);

      Notification.Error("Can't to change original file");
    } finally {
      this.setSwapInProcess(false);
    }
  };

  @action deleteFile = async (fileId: number) => {
    try {
      await api.delete(routesApi.attachedFilePath(fileId));
      void this.fetchEnquiry(this.enquiry.id.toString(), false);
      this.rootStore.activityRecordStore.refetch();
      Notification.Success('Additional file has been removed');
    } catch (e: unknown) {
      console.error(e);

      Notification.Error("Can't remove this file");
    }
  };

  @action reissueProductionParts = async (createOrder: boolean) => {
    this.setReissueInProcess(true);
    try {
      await api.post(routesApi.enquiryReissueProductionPartsPath(this.enquiry.id), { create_order: createOrder });

      void this.rootStore.dealSidebarStore.refetch();

      Notification.Success('Process done!');
    } catch (e: unknown) {
      console.error(e);

      Notification.Error('Process failured!');
    } finally {
      this.setReissueInProcess(false);
    }
  };

  @action setIsFetchingFile = (value: boolean) => {
    this.isFetchingFile = value;
  };

  @action fetchRfqLink = async (id: number, partIds: number[], format: 'xlsx' | 'pdf') => {
    Notification.Success('It could take a few moments. Please wait...');
    this.setIsFetchingFile(true);
    try {
      const fetchUrl = routesApi.enquiryRfqPath(id, partIds, format);
      const response = await api.get(fetchUrl);
      const url = _get(response, 'data.url');

      if (!url) {
        Notification.Error('Sorry, RFQ document not valid in current moment. Please try again later!');
      }

      window.location.href = url;
    } catch (e) {
      console.error(e);
    }

    this.setIsFetchingFile(false);
  };

  @action fetchMoldingCastingRfqLink = async (id: number, partIds: number[]) => {
    Notification.Success('It could take a few moments. Please wait...');
    this.setIsFetchingFile(true);
    try {
      const fetchUrl = routesApi.enquiryDownloadRFQMoldingPath(id, partIds);
      const response = await api.get(fetchUrl);
      const url = _get(response, 'data.url');

      if (!url) {
        Notification.Error('Sorry, RFQ document not valid in current moment. Please try again later!');
      }

      window.location.href = url;
    } catch (e) {
      console.error(e);
    }

    this.setIsFetchingFile(false);
  };

  @action zipCleanedFiles = async (partIds: number[]) => {
    if (!this.enquiry) {
      return;
    }

    this.setIsFetchingFile(true);

    const request = requestBuilder({ responseType: 'blob' });

    try {
      const baseName = `E-${this.rootStore.dealStore.data.pipedriveId || ''}-${this.enquiry.id}-cleaned-drawings`;
      const response = await request.get(
        `${routesApi.enquiryPath(this.enquiry.id)}/download_cleaned_zip?part_ids=${partIds.join('|')}`,
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${baseName}.zip`);

      document.body.appendChild(link);

      link.click();
    } catch {
      notification.error({
        message: 'An error has occured while compressing files',
      });
    }

    this.setIsFetchingFile(false);
  };

  @action zipOriginalFiles = async (partIds: number[]) => {
    if (!this.enquiry) {
      return;
    }

    this.setIsFetchingFile(true);

    const request = requestBuilder({ responseType: 'blob' });

    try {
      const baseName = `E-${this.rootStore.dealStore.data.pipedriveId || ''}-${this.enquiry.id}-original-drawings`;
      const response = await request.get(
        `${routesApi.enquiryPath(this.enquiry.id)}/download_original_zip?part_ids=${partIds.join('|')}`,
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${baseName}.zip`);

      document.body.appendChild(link);

      link.click();
    } catch {
      notification.error({
        message: 'An error has occured while compressing files',
      });
    }

    this.setIsFetchingFile(false);
  };

  @action forceResetParts = async (partIds: number[]) => {
    this.setForceResetLoading(true);

    try {
      await api.post(routesApi.enquiryForceResetParts(this.enquiry.id), decamelizeKeys({ partIds }));
    } catch {
      notification.error({
        message: 'An error has occured while parts reset',
      });
    } finally {
      this.setForceResetLoading(false);
    }
  };

  @action resetDrawings = () => {
    this.drawingsModal.updates = [];
  };

  @action setSimilarParts = (parts: any[]) => {
    this.similarParts = camelizeKeys(parts);
  };

  @action setCurrentlyViewing = (value: ICurrentlyViewing[]): void => {
    this.enquiry.currentlyViewing = value;
  };

  @get get collapsedParts() {
    return this.collapsed;
  }

  @get get isAllPartsSelected() {
    const activeParts = this.enquiry.parts.filter((part) => !part.deleted);

    return !!activeParts.length && this.selected.length === activeParts.length;
  }

  @get get selectedParts() {
    return this.enquiry.parts.filter((part) => this.selected.includes(part.id));
  }

  private showErrors = (e: any) => {
    const code = _get(e, 'response.status');
    const messages: string[] | { base: string[] } = _get(e, 'response.data.errors.source');

    if (code === 422) {
      if (Array.isArray(messages)) {
        Notification.Error(messages.join('. '));
      } else if (typeof messages === 'object' && messages.base && Array.isArray(messages.base)) {
        Notification.Error(messages.base.join('. '));
      } else {
        Notification.Error('Validation Failed');
      }
    } else {
      Notification.Error('Internal server error!');
    }
  };
}

export default EnquiriesStore;
