import { useFilePreview } from 'shared';
import useModal from 'utils/hooks/useModal';

interface Params {
  id: string | number | undefined;
}

export const useAttachmentPreviewModal = (props: Params) => {
  const { data, preview, loading, error } = useFilePreview({ id: props.id });

  const { modalVisible, openModal, closeModal } = useModal();

  const previewData = data?.payload;

  return {
    modalVisible,
    openModal,
    closeModal,
    previewData,
    preview,
    loading,
    error,
  };
};
