import { DeleteOutlined, DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { AttachmentPreview, useAttachmentPreviewModal } from 'components/UI/AttachmentPreview';
import { Button } from 'components/UI/Button/styled';
import { File } from 'components/UI/File/File';
import React from 'react';
import { useDownload } from 'utils/hooks/useDownload';
import { useFileOpener } from 'utils/hooks/useFileOpener';

import s from './FileDownload.module.less';
import { getFileType } from './getFileType';

interface Props {
  name: string;
  id?: string | number;
  url?: string;
  createdAt?: string;
  download?: boolean;
  print?: boolean;
  open?: boolean;
  loading?: boolean;
  className?: string;
  onDelete?: () => void;
}

export const FileDownload = (props: Props) => {
  const { open, loading } = useFileOpener();
  const { download, isDownloading } = useDownload();

  const handleDownload = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!props.url) return;

    void download(props.url, props.name);
  };

  const handlePrint = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!props.url) return;

    void open(props.url, { print: true });
  };

  const handleOpen = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!props.url) return;

    void open(props.url);
  };

  const handleDelete = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e?.preventDefault();
    e?.stopPropagation();

    props.onDelete?.();
  };

  const { isPreviewable, isPDF } = props.name ? getFileType(props.name) : { isPreviewable: false, isPDF: false };

  const actions = (
    <>
      {isPreviewable && props.print && (
        <Button className={s.action} size="small" type="link" onClick={handlePrint}>
          <PrinterOutlined />
        </Button>
      )}
      {props.download && (
        <Button className={s.action} size="small" type="link" onClick={handleDownload}>
          <DownloadOutlined />
        </Button>
      )}
      {props.onDelete && (
        <Popconfirm title="Are you sure you want to delete this file?" onConfirm={handleDelete}>
          <Button className={s.action} danger size="small" type="link">
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      )}
    </>
  );

  const { modalVisible, openModal, closeModal, previewData, preview } = useAttachmentPreviewModal({ id: props.id });

  const handlePreview = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    await preview();
    openModal();
  };

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isPreviewable) {
      void handlePreview(e);

      return;
    }

    void handleOpen(e);
  };

  return (
    <>
      <File
        className={props.className}
        actions={actions}
        onClick={handleClick}
        loading={loading || isDownloading}
        {...props}
      />
      <AttachmentPreview
        name={previewData?.name}
        url={previewData?.bigUrl}
        isPDF={isPDF}
        modalVisible={modalVisible}
        closeModal={closeModal}
      />
    </>
  );
};
