import { RfqsOffersDecisionStateEnumType, RfqsOffersResponseStateEnumType } from '__generated__/types';

interface VisualData {
  label: string;
  color: string;
}

export const responseStateMapping: Record<RfqsOffersResponseStateEnumType, VisualData> = {
  [RfqsOffersResponseStateEnumType.Canceled]: { label: 'Canceled', color: '#CB4239' },
  [RfqsOffersResponseStateEnumType.Empty]: { label: 'No response', color: '#F1830D' },
  [RfqsOffersResponseStateEnumType.Interested]: { label: 'Interested', color: '#F1830D' },
  [RfqsOffersResponseStateEnumType.Rejected]: { label: 'Rejected', color: '#CB4239' },
  [RfqsOffersResponseStateEnumType.Submitted]: { label: 'Responded', color: '#29A340' },
};

export const responseOptions = Object.values(RfqsOffersResponseStateEnumType).map((responseStateName) => {
  return { value: responseStateName, label: responseStateMapping[responseStateName].label };
});

export const decisionStateMapping: Record<RfqsOffersDecisionStateEnumType, VisualData> = {
  [RfqsOffersDecisionStateEnumType.Accepted]: { label: 'Accepted', color: '#219653' },
  [RfqsOffersDecisionStateEnumType.Negotiating]: { label: 'Negotiating', color: '#F1830D' },
  [RfqsOffersDecisionStateEnumType.Pending]: { label: 'Pending', color: '#F1830D' },
  [RfqsOffersDecisionStateEnumType.Rejected]: { label: 'Declined', color: '#DC3545' },
};

export const decisionOptions = Object.values(RfqsOffersDecisionStateEnumType).map((decisionStateName) => {
  return { value: decisionStateName, label: decisionStateMapping[decisionStateName].label };
});
