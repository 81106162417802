import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type RankFragment = { total?: number | null, timing?: number | null, overdueRatio?: number | null, quality?: number | null, packing?: number | null, communication?: number | null, poQuantity?: number | null, qualityRatio?: number | null, cancelRatio?: number | null, delayedProviderOrdersQuantity?: number | null };

export const RankFragmentDoc = gql`
    fragment rank on Rank {
  total
  timing
  overdueRatio
  quality
  packing
  communication
  poQuantity
  qualityRatio
  cancelRatio
  delayedProviderOrdersQuantity
}
    `;